import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';

import { gettext } from 'core/utils/text';
import styles from './BottomBar.scss';

const BottomBar = ({ onCancel, onSave, disableButtons }) => (
    <ButtonToolbar className={styles.bar}>
        <Button
            type="button"
            onClick={onCancel}
            bsStyle="link"
            disabled={disableButtons}
        >
            {gettext('Cancel')}
        </Button>
        <Button
            type="button"
            bsStyle="success"
            onClick={onSave}
            disabled={disableButtons}
        >
            {gettext('Save')}
        </Button>
    </ButtonToolbar>
);

BottomBar.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disableButtons: PropTypes.bool.isRequired,
};

export default BottomBar;
