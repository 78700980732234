import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipOverlay = ({
    tooltipKey,
    tooltipPlacement,
    tooltipText,
    tooltipClassName,
    children,
}) =>
    tooltipText ? (
        <OverlayTrigger
            key={tooltipKey}
            placement={tooltipPlacement}
            overlay={
                <Tooltip
                    id={`tooltip-${tooltipPlacement}`}
                    className={tooltipClassName}
                >
                    {tooltipText}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    ) : (
        children
    );

TooltipOverlay.propTypes = {
    tooltipKey: PropTypes.string.isRequired,
    tooltipPlacement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    tooltipClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default TooltipOverlay;
