import { call, put, takeEvery } from 'redux-saga/effects';
import api from 'core/utils/api';
import { affixDowntimeRoutine, solveDowntimeRoutine } from '../ducks/downtimes';
import {
    pauseTimeTrackerRoutine,
    startTimeTrackerRoutine,
} from '../ducks/timeTrackers';

export function* watchAffixDowntime() {
    yield takeEvery(affixDowntimeRoutine.TRIGGER, function* saga({ payload }) {
        try {
            const data = yield call(() =>
                api.downtimes.list.post(null, payload),
            );
            yield put(
                pauseTimeTrackerRoutine.success({
                    timeTracker: data.time_tracker,
                }),
            );
            yield put(affixDowntimeRoutine.success({ downtime: data }));
        } catch (error) {
            console.error(error);
            yield put(affixDowntimeRoutine.failure(error));
        }
    });
}

export function* watchSolveDowntime() {
    yield takeEvery(solveDowntimeRoutine.TRIGGER, function* saga({ payload }) {
        try {
            yield call(() => api.downtimes.solve.patch({ pk: payload.id }));
            yield put(
                startTimeTrackerRoutine.trigger({
                    work_order: payload.work_order,
                }),
            );
            yield put(solveDowntimeRoutine.success(payload.id));
        } catch (error) {
            console.error(error);
            yield put(solveDowntimeRoutine.failure(error));
        }
    });
}
