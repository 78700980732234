import { all } from 'redux-saga/effects';

import { watchFetchStations } from 'stations/sagas/stations';
import { watchSubscriptions } from 'stations/sagas/stationsSubscriptions';
import { partsOperationsSaga } from 'pages/parts/PartsOperationsPage';

export default function* rootSaga() {
    yield all([
        watchFetchStations(),
        watchSubscriptions(),
        partsOperationsSaga(),
    ]);
}
