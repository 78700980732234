export const STATE_KEY = 'stateHash';

// region =========== Action types ===========

const SET_STATE_HASH = STATE_KEY + '/SET_STATE_HASH';

// endregion

// region =========== Reducers ===========

/**
 * @param {StateHashState} state
 * @param {Object} action
 * @returns {StateHashState}
 */
const stateHash = (state = null, action) => {
    switch (action.type) {
        case SET_STATE_HASH:
            return action.hash;
        default:
            return state;
    }
};

export default stateHash;

// endregion

// region =========== Action creators ===========

/**
 * Update the state hash.
 *
 * @param {?String} hash
 *
 * @returns {{type: String, hash: ?String}}
 */
export const setStateHash = (hash) => ({ type: SET_STATE_HASH, hash });

// endregion
