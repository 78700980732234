// An idea of this pattern: https://bit.ly/2NDXjEg

export default (state = {}, action) => {
    const { type } = action;
    const matches = /(.*)\/(TRIGGER|REQUEST|SUCCESS|FAILURE)(_DETAILS)*/.exec(
        type,
    );

    // ignore all actions except TRIGGER/REQUEST/SUCCESS/FAILURE
    if (!matches) return state;

    const [, stateKey, requestState, details] = matches;
    const storageKey = details ? stateKey + 'Details' : stateKey;
    // store true for REQUEST / TRIGGER and false for SUCCESS or FAILURE
    return {
        ...state,
        [storageKey]: requestState === 'REQUEST' || requestState === 'TRIGGER',
    };
};

/**
 * Selector function that returns true when all actions is not loading
 * or if provided actions isn't set yet (because REQUEST starts only after mounting components)
 *
 * @param state
 * @param {string[]} stateKeys
 * @returns {boolean}
 */
export const getIsLoading = (state, stateKeys) =>
    stateKeys.some((key) => state.loading[key] ?? false);
