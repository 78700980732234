import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { clsx } from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RenderPropConfirmModal from 'core/components/RenderPropConfirmModal';
import { affixDowntimeRoutine } from 'parts/operations/ducks/downtimes';
import { gettext } from 'core/utils/text';
import { TagsMultiSelect } from 'core/components/TagsMultiSelect';

import api from 'core/utils/api';
import { FaExclamationCircle } from 'react-icons/fa';
import styles from './Styles.scss';

const AffixDowntimeButton = ({ workOrderId, isRunning, affixDowntime }) => {
    const [downTimeProblems, setDownTimeProblems] = useState([]);

    const onConfirm = () => {
        affixDowntime({
            work_order: workOrderId,
            problems: downTimeProblems.map((problem) => problem.id),
        });
        setDownTimeProblems([]);
    };

    return (
        <RenderPropConfirmModal
            title={gettext('Are you sure you’d like to activate downtime?')}
            onConfirm={onConfirm}
            isValid={!!downTimeProblems.length}
            onCancel={() => setDownTimeProblems([])}
            modalContent={
                <>
                    {/* eslint-disable-next-line max-len */}
                    <p>
                        {gettext(
                            'The work will be put on pause and downtime will be activated.  This task can’t be progressed until downtime is solved.',
                        )}
                    </p>
                    <TagsMultiSelect
                        handleChange={setDownTimeProblems}
                        selectedTags={downTimeProblems}
                        apiEndPoint={api.partProducts.downTimeProblemTagList}
                        labelText={gettext('Cause of problem')}
                    />
                </>
            }
        >
            {({ requestConfirm }) => (
                <button
                    className={clsx(styles.affixDowntimeButton, {
                        [styles.isRunning]: isRunning,
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                        requestConfirm();
                    }}
                >
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                {gettext('Activate downtime')}
                            </Tooltip>
                        }
                    >
                        <FaExclamationCircle
                            className={styles.affixDowntime}
                            aria-label={gettext('Activate downtime')}
                        />
                    </OverlayTrigger>
                </button>
            )}
        </RenderPropConfirmModal>
    );
};

AffixDowntimeButton.propTypes = {
    workOrderId: PropTypes.number.isRequired,
    isRunning: PropTypes.bool,
    affixDowntime: PropTypes.func.isRequired,
};

AffixDowntimeButton.defaultProps = {
    isRunning: false,
};

export default connect(null, { affixDowntime: affixDowntimeRoutine })(
    AffixDowntimeButton,
);
