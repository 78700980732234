import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import TooltipOverlay from 'core/components/TooltipOverlay';

import { FaComment } from 'react-icons/fa';
import styles from './PartProduct.scss';

const PartProductComment = ({ children = '', wrap = false }) => (
    <div className={styles.partProductCommentContainer}>
        <FaComment />
        {wrap ? (
            <div className={styles.commentText}>{children}</div>
        ) : (
            <TooltipOverlay
                tooltipKey="top"
                tooltipPlacement="top"
                tooltipText={children}
            >
                <div
                    className={clsx(styles.commentText, styles.commentOverflow)}
                >
                    {children}
                </div>
            </TooltipOverlay>
        )}
    </div>
);

PartProductComment.propTypes = {
    children: PropTypes.string,
    wrap: PropTypes.bool,
};

export default PartProductComment;
