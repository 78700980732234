import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { gettext } from 'core/utils/text';
import { componentTagPropType } from '../../parts/workplan/propTypes';

export const TagsMultiSelect = ({
    handleChange,
    selectedTags,
    apiEndPoint,
    labelText,
}) => {
    const getTags = (input) =>
        apiEndPoint
            .fetch(null, input ? { search: input } : {})
            .then((res) => ({ options: res }));

    return (
        <div className="section component-tags-multi-selector">
            <label key="label" className="control-label" htmlFor="part_tags">
                {labelText}
            </label>
            <Select.Async
                id="part_tags"
                multi
                loadOptions={getTags}
                labelKey="name"
                valueKey="id"
                closeOnSelect
                value={selectedTags}
                disabled={false}
                onChange={handleChange}
                clearable={false}
                searchPromptText={gettext('Type to search')}
                placeholder={gettext('Search')}
                className="form-control select-multi"
            />
        </div>
    );
};

TagsMultiSelect.propTypes = {
    apiEndPoint: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    selectedTags: PropTypes.arrayOf(componentTagPropType),
    labelText: PropTypes.func.isRequired,
};

TagsMultiSelect.defaultProps = {
    selectedTags: [],
};
