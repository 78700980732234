import { normalize } from 'normalizr';

import createEntityDuck from 'core/ducks/generic/entity';
import {
    partProductsSchema,
    RECEIVE_ORDERS,
    RECEIVE_PRODUCTS,
    RECEIVE_UNPLANNED_PRODUCTS,
} from 'parts/timeplan/actions/timeplanActions';
import { workOrderActionsSelectors } from 'parts/workplan/ducks/workOrderActions';
import { actionsSelectors } from 'parts/workplan/ducks/actions';
import { getWorkOrderIdFromActionGroupId } from 'parts/utils/parts';
import { operationsPageRoutine } from 'parts/operations/sagas/operationsViewSaga';
import {
    stopTimeTrackerRoutine,
    timeTrackersSelectors,
} from 'parts/operations/ducks/timeTrackers';
import { downtimesSelectors } from 'parts/operations/ducks/downtimes';
import {
    WORK_ORDER_ACTION_STATUSES,
    WORK_ORDER_STATUS,
} from 'parts/workplan/constants';

/**
 * @typedef {Object} WorkOrderNormalized
 * @property {number} id
 * @property {string} start_time
 * @property {string} end_time
 * @property {number} duration
 * @property {number} part_product
 * @property {number} worker
 * @property {number[]} work_order_actions List of WorkOrderAction ids
 */

/**
 * @typedef
 * {import('core/ducks/generic/entity').EntityDuck<WorkOrderNormalized>}
 * WorkOrderEntityState
 */

/**
 * @typedef {WorkOrderNormalized & {
 *      workOrderActions:
 *      import('parts/workplan/ducks/actions').WorkPlanActionSerialized[]
 * }} WorkOrderWithActions
 */

/**
 * @typedef {WorkOrderNormalized & {
 *  work_order_actions:
 *  import('parts/workplan/ducks/workOrderActions').WorkOrderActionDenormalized[]
 * }} WorkOrderDeserialized
 */

export const STATE_KEY = 'workOrders';

const [baseReducer, workOrdersRoutine, selectors] = createEntityDuck(STATE_KEY);

const workOrdersReducer = (currentState = undefined, action) => {
    // First evaluate the base reducer
    const state = baseReducer(currentState, action);

    // And then we want to react to a few other events
    switch (action.type) {
        case RECEIVE_ORDERS: {
            const { entities } = normalize(action.results.products, [
                partProductsSchema,
            ]);

            return baseReducer(
                baseReducer(state, workOrdersRoutine.initialize()),
                workOrdersRoutine.success({
                    byId: entities.workOrders ?? {},
                }),
            );
        }
        case RECEIVE_PRODUCTS: {
            const normalized = normalize(action.products, [partProductsSchema]);

            // Remove all work orders for the part products
            const workOrdersToRemove = action.products
                .reduce(
                    (acc, product) => [
                        ...acc,
                        ...state.allIds
                            .map((id) => state.byId[id])
                            .filter(
                                (workOrder) =>
                                    workOrder.part_product === product.id,
                            ),
                    ],
                    [],
                )
                .map((workOrder) => workOrder.id);

            return baseReducer(
                baseReducer(
                    state,
                    workOrdersRoutine.deleteMultiple(workOrdersToRemove),
                ),
                workOrdersRoutine.success({
                    byId: normalized.entities.workOrders,
                    allIds: Object.keys(
                        normalized.entities.workOrders ?? {},
                    ).map((id) => parseInt(id, 10)),
                }),
            );
        }
        case RECEIVE_UNPLANNED_PRODUCTS: {
            const normalized = normalize(action.products, [partProductsSchema]);

            const removedWorkOrderIds = action.unplannedIds.map(
                getWorkOrderIdFromActionGroupId,
            );

            return baseReducer(
                baseReducer(
                    state,
                    workOrdersRoutine.deleteMultiple(removedWorkOrderIds),
                ),
                workOrdersRoutine.success({
                    byId: normalized.entities.workOrders,
                    allIds: Object.keys(
                        normalized.entities.workOrders ?? {},
                    ).map((id) => parseInt(id, 10)),
                }),
            );
        }
        case operationsPageRoutine.SUCCESS:
        case stopTimeTrackerRoutine.SUCCESS:
            return baseReducer(
                state,
                workOrdersRoutine.success({
                    byId: action.payload.workOrders,
                    allIds: action.payload.workOrderIds,
                }),
            );

        default:
            return state;
    }
};

export default workOrdersReducer;

const workOrdersSelectors = {
    ...selectors,
    /**
     * Select a single work order from the state as well as all actions related
     * to it.
     * @param {object} state
     * @param {number} id
     * @returns {WorkOrderWithActions}
     */
    byIdWithActions: (state, id) => {
        const workOrder = selectors.getById(state, id);
        if (!workOrder) return undefined;

        return {
            ...workOrder,
            workOrderActions: workOrder.work_order_actions
                .map((workOrderActionId) =>
                    workOrderActionsSelectors.getById(state, workOrderActionId),
                )
                .map((workOrderAction) =>
                    actionsSelectors.byId(state, workOrderAction.action),
                ),
        };
    },

    listForPartProductAndWorker: (state, partProductId, workerId) =>
        selectors
            .getList(state)
            .filter(
                (workOrder) =>
                    workOrder.part_product === partProductId &&
                    workOrder.worker === workerId,
            ),

    /**
     * Calculate the status of the work order based on its actions and time
     * trackers.
     *
     * While the back-end calculates the status in a computed property,
     * calculating it again in the front-end makes it much easier to keep
     * up-to-date. For example, the status of the work order should update when
     * its tracker is started or paused.
     */
    getWorkOrderStatus: (state, workOrderId) => {
        const downtime = downtimesSelectors.getByWorkOrderId(
            state,
            workOrderId,
        );
        if (downtime) {
            return WORK_ORDER_STATUS.DOWNTIME;
        }

        const tracker = timeTrackersSelectors.getNewestTracker(
            state,
            workOrderId,
        );
        const workOrderActions = workOrderActionsSelectors
            .getList(state)
            .filter((woAction) => woAction.work_order === workOrderId);

        if (!tracker) {
            return WORK_ORDER_STATUS.NOT_STARTED;
        }

        if (!tracker.end_time) {
            return WORK_ORDER_STATUS.RUNNING;
        }

        const allActionsDone = workOrderActions.every(
            (woAction) => woAction.status === WORK_ORDER_ACTION_STATUSES.DONE,
        );
        if (allActionsDone) {
            return WORK_ORDER_STATUS.DONE;
        }

        return WORK_ORDER_STATUS.PAUSED;
    },

    getNotFinished: (state) =>
        selectors
            .getList(state)
            .filter((workOrder) =>
                workOrder.work_order_actions
                    .map((woActionId) =>
                        workOrderActionsSelectors.getById(state, woActionId),
                    )
                    .some(
                        (woAction) =>
                            woAction.status !== WORK_ORDER_ACTION_STATUSES.DONE,
                    ),
            ),
};

export { workOrdersSelectors, workOrdersRoutine };
