import { call, put, takeEvery } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';

import { STATE_KEY, subscriptionsRoutine } from 'stations/ducks/subscriptions';
import api from 'core/utils/api';

const subscriptionSchema = new schema.Entity(STATE_KEY, {});

function* fetchSubscriptionAsync(param) {
    try {
        const data = yield call(() =>
            api.stations.subscriptionDetail.fetch({ pk: param.payload }),
        );
        const { entities } = normalize(data, subscriptionSchema);
        yield put(subscriptionsRoutine.successDetails(entities[STATE_KEY]));
    } catch (error) {
        yield put(subscriptionsRoutine.failure(error));
    }
}

export function* watchFetchSubscription() {
    yield takeEvery(
        subscriptionsRoutine.REQUEST_DETAILS,
        fetchSubscriptionAsync,
    );
}
