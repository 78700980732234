import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { gettext } from 'core/utils/text';
import ConfirmModal from './ConfirmModal';

const RenderPropConfirmModal = ({
    children,
    onConfirm,
    onCancel,
    title,
    yesText,
    noText,
    modalContent,
    isValid,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        onCancel();
        setIsOpen(false);
    };

    const handleConfirm = () => {
        setIsOpen(false);
        onConfirm();
    };

    const requestConfirm = () => {
        setIsOpen(true);
    };

    const defaultTitle = gettext('Are you sure you want to do this?');

    return (
        <>
            {createPortal(
                <ConfirmModal
                    title={title || defaultTitle}
                    isOpen={isOpen}
                    onConfirm={handleConfirm}
                    onCancel={handleClose}
                    yesText={yesText}
                    noText={noText}
                    isValid={isValid}
                >
                    {modalContent}
                </ConfirmModal>,
                document.body,
            )}
            {children({
                requestConfirm,
            })}
        </>
    );
};

RenderPropConfirmModal.propTypes = {
    children: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    modalContent: PropTypes.node,
    isValid: PropTypes.bool,
};

RenderPropConfirmModal.defaultProps = {
    isValid: undefined,
    onCancel: () => {},
};

export default RenderPropConfirmModal;
