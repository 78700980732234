import { createRoutine } from 'redux-saga-routines';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from 'core/utils/api';
import { normalize, schema } from 'normalizr';

export const workOrderActionsUpdateRoutine = createRoutine(
    'workOrderActions/update',
);

const workOrderActionSchema = new schema.Entity('workOrderActions', {
    action: new schema.Entity('actions'),
});

function* updateStatuses(data) {
    const response = yield call(
        [api.workPlan.workOrderActionsBulkUpdate, 'put'],
        null,
        data.payload.changes,
    );

    const { entities } = normalize(response, [workOrderActionSchema]);

    yield put(
        workOrderActionsUpdateRoutine.success({
            workOrderActions: entities.workOrderActions,
        }),
    );
}

export function* watchWorkOrderActions() {
    yield takeEvery(workOrderActionsUpdateRoutine.TRIGGER, updateStatuses);
}
