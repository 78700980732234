import { normalize } from 'normalizr';

import createEntityDuck from 'core/ducks/generic/entity';
import {
    partProductsSchema,
    RECEIVE_ORDERS,
    RECEIVE_UNPLANNED_PRODUCTS,
} from 'parts/timeplan/actions/timeplanActions';
import { getWorkOrderIdFromActionGroupId } from 'parts/utils/parts';
import { operationsPageRoutine } from 'parts/operations/sagas/operationsViewSaga';
import { workOrderActionsUpdateRoutine } from 'parts/operations/sagas/workOrderActionsSaga';
import { stopTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';

/**
 * @typedef {Object} WorkOrderActionNormalized
 * @property {number} id
 * @property {number} work_order Work order ID
 * @property {number} action Action ID
 */

/**
 * @typedef {WorkOrderActionNormalized & {
 *  action: import('parts/workplan/ducks/actions').WorkPlanActionSerialized
 * }} WorkOrderActionDenormalized
 */

/**
 * @typedef
 * {import('core/ducks/generic/entity').EntityDuck<WorkOrderActionNormalized>}
 * WorkOrderActionsEntityState
 */

export const STATE_KEY = 'workOrderActions';

const [
    baseReducer,
    workOrderActionsRoutine,
    workOrderActionsSelectors,
] = createEntityDuck(STATE_KEY);

const workOrderActionsReducer = (currentState = undefined, action) => {
    // First evaluate the base reducer
    const state = baseReducer(currentState, action);

    // And then we want to react to a few other events
    switch (action.type) {
        case RECEIVE_ORDERS: {
            const { entities } = normalize(action.results.products, [
                partProductsSchema,
            ]);

            return baseReducer(
                state,
                workOrderActionsRoutine.success({
                    byId: entities.workOrderActions ?? {},
                }),
            );
        }
        case RECEIVE_UNPLANNED_PRODUCTS: {
            const normalized = normalize(action.products, [partProductsSchema]);

            const removedWorkOrderIds = action.unplannedIds
                .map(getWorkOrderIdFromActionGroupId)
                .filter(Boolean);

            const removedWorkOrderActionIds = Object.values(state.byId)
                .filter((workOrderAction) =>
                    removedWorkOrderIds.includes(workOrderAction.work_order),
                )
                .map((workOrderAction) => workOrderAction.id);

            return baseReducer(
                baseReducer(
                    state,
                    workOrderActionsRoutine.deleteMultiple(
                        removedWorkOrderActionIds,
                    ),
                ),
                workOrderActionsRoutine.success({
                    byId: normalized.entities.workOrderActions || {},
                }),
            );
        }
        case operationsPageRoutine.SUCCESS:
        case stopTimeTrackerRoutine.SUCCESS:
            return baseReducer(
                state,
                workOrderActionsRoutine.success({
                    byId: action.payload.workOrderActions,
                }),
            );
        case workOrderActionsUpdateRoutine.SUCCESS:
            return baseReducer(
                state,
                workOrderActionsRoutine.success({
                    byId: action.payload.workOrderActions,
                }),
            );

        default:
            return state;
    }
};

export default workOrderActionsReducer;

export { workOrderActionsSelectors, workOrderActionsRoutine };
