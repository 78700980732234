import { getOperatorLabel } from 'accounts/ducks/operators';
import { actionGroupId, formatActionGroup } from 'parts/utils/parts';
import { workOrdersSelectors } from 'parts/workplan/ducks/workOrders';

/**
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').PartTimeplanState}
 * PartTimeplanState
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').ActionGroupAllInfo}
 * ActionGroupAllInfo
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').ActionGroup}
 * ActionGroup
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').ActionGroupState}
 * ActionGroupState
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').ArticleGroup}
 * ArticleGroup
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').PartProduct}
 * PartProduct
 */

/**
 * @typedef {import('core/timeplan/reducers/common').OrdersState} OrdersState
 * @typedef {import('core/timeplan/reducers/common').ClientsState} ClientsState
 */

/**
 * @param {PartTimeplanState} state
 * @returns {string[]}
 */
export const getUnplannedActionGroupIds = (state) =>
    Object.keys(state.actionGroups).filter(
        (id) => !state.tree.plannedLeaves.includes(id),
    );

/**
 * Attach more information to the action groups with the given IDs.
 *
 * @param {Array<string>} actionGroupIds
 * @param {{
 *     articleGroups: Object.<string, ArticleGroup>,
 *     partProducts: Object.<string, PartProduct>,
 *     actionGroups: ActionGroupState,
 *     clients: ClientsState,
 *     orders: OrdersState,
 *     entities: {
 *          workOrders: import('parts/workplan/ducks/workOrders').WorkOrderEntityState,
 *          workOrderActions: import('parts/workplan/ducks/workOrderActions').WorkOrderActionsEntityState,
 *     },
 * }} state
 *
 * @return {ActionGroupAllInfo[]}
 */
export const attachAllInfoToActionGroups = (actionGroupIds, state) =>
    actionGroupIds.map((id) => {
        const actionGroup = state.actionGroups[id];
        if (!actionGroup) {
            console.warn(
                `Could not find action group with id ${id} in Redux store!`,
            );
        }

        const partProduct = state.partProducts[actionGroup.partProductId];
        const articleGroup = state.articleGroups[partProduct.articleGroupId];
        const order = state.orders[articleGroup.orderId];
        const client = state.clients[order.clientId];

        return formatActionGroup({
            ...actionGroup,
            partProduct,
            articleGroup,
            order,
            client,
            workOrder: {
                ...actionGroup.workOrder,
                workOrderActions: actionGroup.workOrder
                    ? actionGroup.workOrder.work_order_actions.map(
                          (woa) => woa.action,
                      )
                    : undefined,
            },
        });
    });

/**
 * Get the bubbles that have been planned in the "ActionGroup" format.
 *
 * @param {PartTimeplanState} state
 *
 * @return {ActionGroupAllInfo[]}
 */
export const getPlannedActionGroups = (state) => {
    const workOrders = workOrdersSelectors.getNotFinished(state);

    // sanity check, as per KP-263
    const brokenStateFilter = (id) => {
        if (!state.actionGroups[id]) {
            // HOT LAVA! This is not OK, please deal with it ASAP!
            console.error(
                `Work order action group ID '${id}' not in actionGroups`,
            );
            return false;
        }
        return true;
    };

    // All work orders are "planned action groups", so, we can get all the work
    // orders and just find the matching action group from the store.
    return attachAllInfoToActionGroups(
        workOrders
            .map((workOrder) =>
                actionGroupId(
                    workOrder.part_product,
                    workOrder.worker,
                    workOrder.id,
                ),
            )
            .filter(brokenStateFilter),
        state,
    );
};

/**
 * Get the selected action groups. Needs to also return a label, since it is
 * needed while showing the drag and drop preview.
 *
 * @param {PartTimeplanState} state
 *
 * @returns {Array<ActionGroup>}
 */
export const getSelectedActionGroups = (state) =>
    state.tree.selectedLeaves.map((leafId) => ({
        ...state.actionGroups[leafId],
        label: getOperatorLabel(
            state.operators,
            state.actionGroups[leafId].workerId,
        ),
    }));

/**
 * Get the dropping action groups that are being dragged.
 *
 * @param {PartTimeplanState} state
 *
 * @returns {Array<ActionGroupAllInfo>}
 */
export const getDroppingActionGroups = (state) =>
    attachAllInfoToActionGroups(state.droppingIds, state);
