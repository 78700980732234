import PropTypes from 'prop-types';

import { ComponentPropType } from './workplan/propTypes';

/**
 * Used for removing either a component, part or action
 * Indexes are kept in sync by passing them in props
 */
export const IndexesPropType = PropTypes.shape({
    componentIndex: PropTypes.number.isRequired,
    partIndex: PropTypes.number,
    actionIndex: PropTypes.number,
});

export const PartProductPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    action_formula_errors: PropTypes.shape({
        el: PropTypes.arrayOf(PropTypes.string),
        keev: PropTypes.arrayOf(PropTypes.string),
        mt: PropTypes.arrayOf(PropTypes.string),
    }),
    active: PropTypes.bool,
    el_time: PropTypes.number,
    is_part: PropTypes.bool,
    keev_time: PropTypes.number,
    mt_time: PropTypes.number,
    order_row: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
    production_status: PropTypes.number,
    total_time: PropTypes.number,
    work_plan_json: PropTypes.arrayOf(ComponentPropType),
    work_plan_tag: PropTypes.string,
    comment: PropTypes.string,
});
