import createEntityDuck from 'core/ducks/generic/entity';

export const STATE_KEY = 'subscriptions';

const [
    subscriptionsReducer,
    subscriptionsRoutine,
    subscriptionsSelectors,
] = createEntityDuck(STATE_KEY, true);

export default subscriptionsReducer;
export { subscriptionsRoutine, subscriptionsSelectors };
