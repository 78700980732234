import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import * as qs from "qs";
// import { RootState, appUserSlice } from "@lib/store";
// import { resolveBaseUrl } from "@lib/utils";

// eslint-disable-next-line no-console
// console.log("API Base URL:", resolveBaseUrl());

// export const nextBaseQuery = fetchBaseQuery({
// baseUrl: resolveBaseUrl(),
// prepareHeaders: async (headers, options) => {
//     const state = (options.getState() as RootState)[appUserSlice.name];
//     const { accessToken, locale } = state;
//     headers.set("Accept-Language", locale);
//     if (accessToken) {
//         headers.set("Authorization", `Bearer ${accessToken}`);
//     }
//     return headers;
// },
// paramsSerializer: (params) =>
//     qs.stringify(params, {
//         arrayFormat: "repeat",
//     }),
// });

// /* As Backend and Next APIs use same Mongo, we can assume arguably that this is the same API and needs to reside in
//  * same API slice. To achieve this, dynamically put correct base URL and authentication. */

export const baseQueriesApi = createApi({
    baseQuery: fetchBaseQuery({}),
    tagTypes: [],
    endpoints: () => ({}),
});
