import uniq from 'lodash/uniq';
import { createRoutineCreator } from 'redux-saga-routines';

/**
 * @typedef {Object} entityRoutine
 * @property {string} REQUEST
 * @property {string} SUCCESS
 * @property {string} FAILURE
 * @property {string} REQUEST_DETAILS
 * @property {string} SUCCESS_DETAILS
 * @property {string} FAILURE_DETAILS
 * @property {string} DELETE
 * @property {string} DELETE_MULTIPLE
 * @property {string} UPDATE_CURSOR
 * @property {string} INITIALIZE
 * @property {function} request
 * @property {function} success
 * @property {function} failure
 * @property {function} requestDetails
 * @property {function} successDetails
 * @property {function} failureDetails
 * @property {function} delete
 * @property {function} deleteMultiple
 * @property {function} updateCursor
 * @property {function} initialize
 */

/**
 * @template Type
 * @typedef {Object} EntityDuck
 * @property {Object.<number, T>} byId
 * @property {Object.<number, T>} detailsById
 * @property {ID[]} allIds
 */

/**
 * @template Type
 * @typedef {Object} Selectors
 * @property {Function} getList
 * @property {(state: object, id: number) => Type} getById
 * @property {Function} getDetailsById
 */

/**
 * @typedef {Object} EntityState
 * @property {Object} byId Object where keys are ids of elements inside
 * @property {Object} detailsById Object where stored detailed information about elements
 * @property {number[]} allIds array of all elements' ids
 */
const defaultInitialState = {
    byId: {},
    detailsById: {},
    allIds: [],
};

const cursor = {
    next: null,
    previous: null,
};

/**
 * Returns generated for entity reducer, routines object (that actually actions' generator), and selectors.
 * Additional parameters can be passed, such withCursor and initialState.
 *
 * @template Type
 * @param {string} stateKey Prefix for redux actions
 * @param {boolean} withCursor Add to the state an additional object with cursor's link if you'd like to use pagination
 * @param {EntityState} initialState
 * @return {[
 *      function,
 *      entityRoutine,
 *      Selectors<Type>,
 * ]}
 *
 * @example
 * const [entityReducer, entityRoutine] = createEntityDuck('users');
 * // inside of a component, you can use simple mapDispatchToProps object and
 * // calling `entityRoutine` will cause "users/REQUEST" action.
 * const mapDispatchToProps = {
 *      entityRoutine,
 * };
 * // But if you need to call other actions inside of a component,
 * // you have to dispatch them manually
 * const mapDispatchToProps = dispatch => ({
 *      getUser: id => dispatch(entityRoutine.requestDetails(id)),
 * });
 * // For `SUCCESS` action payload has to have structure as below
 * {byId: {...}, allIds: [...]}
 */
const createEntityDuck = (
    stateKey,
    withCursor = false,
    initialState = defaultInitialState,
) => {
    const payloadProcessing = (payload) => ({
        byId: payload.byId,
        allIds:
            payload.allIds ||
            Object.keys(payload.byId).map((id) => parseInt(id, 10)),
    });

    /** @type entityRoutine */
    const entityRoutine = createRoutineCreator([
        'REQUEST',
        'SUCCESS',
        'FAILURE',
        'REQUEST_DETAILS',
        'SUCCESS_DETAILS',
        'FAILURE_DETAILS',
        'DELETE',
        'DELETE_MULTIPLE',
        'UPDATE_CURSOR',
        'INITIALIZE',
    ])(stateKey, {
        success: payloadProcessing,
    });

    const configuredState = {
        ...initialState,
        ...(withCursor && { cursor }),
    };
    const entityReducer = (state = configuredState, action) => {
        switch (action.type) {
            case entityRoutine.SUCCESS:
                return {
                    ...state,
                    byId: { ...state.byId, ...action.payload.byId },
                    // TODO: should be tested for performance issues
                    allIds: action.payload.allIds
                        ? uniq([...state.allIds, ...action.payload.allIds])
                        : uniq([
                              ...state.allIds,
                              Object.keys(action.payload.byId).map((id) =>
                                  parseInt(id, 10),
                              ),
                          ]),
                };

            case entityRoutine.SUCCESS_DETAILS:
                return {
                    ...state,
                    detailsById: { ...state.detailsById, ...action.payload },
                };

            case entityRoutine.DELETE: {
                const { ...byId } = state.byId;
                delete byId[action.payload];
                const allIds = state.allIds.filter(
                    (id) => id !== action.payload,
                );
                return {
                    ...state,
                    byId,
                    allIds,
                };
            }

            case entityRoutine.DELETE_MULTIPLE: {
                const idsToDelete = action.payload;
                const allIds = state.allIds.filter(
                    (id) => !idsToDelete.includes(id),
                );
                const byId = Object.values(state.byId)
                    .filter((entity) => !idsToDelete.includes(entity.id))
                    .reduce(
                        (acc, entity) => ({
                            ...acc,
                            [entity.id]: entity,
                        }),
                        {},
                    );

                return {
                    ...state,
                    byId,
                    allIds,
                };
            }

            case entityRoutine.UPDATE_CURSOR:
                return {
                    ...state,
                    cursor: action.payload,
                };

            case entityRoutine.INITIALIZE:
                return {
                    byId: {},
                    allIds: [],
                    detailsById: {},
                };
            default:
                return state;
        }
    };

    const getList = (state) =>
        state.entities[stateKey].allIds.map(
            (id) => state.entities[stateKey].byId[id],
        );
    const getById = (state, id) => state.entities[stateKey].byId[id];
    const getDetailsById = (state, id) =>
        state.entities[stateKey].detailsById[id];
    const selectors = { getList, getById, getDetailsById };

    return [entityReducer, entityRoutine, selectors];
};

export default createEntityDuck;
