import moment from 'moment';

import { getWorkTimeBlockers } from 'core/timeplan/utils/workTimeBlockers';
import { formatActionGroup } from 'parts/utils/parts';

/**
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').ActionGroupAllInfo}
 * ActionGroupAllInfo
 */

/**
 * @typedef {import('accounts/overtimes/ducks/overtimes').Overtime} Overtime
 */

// region =========== Type definitions ===========

/**
 * @typedef {Object} Gap
 * @property {moment.Moment} start
 * @property {Number} duration - in minutes
 */

/**
 * @typedef {Object} Blocker
 * @property {moment.Moment} start
 * @property {moment.Moment} end
 */

/**
 * @typedef {Blocker} PlanningBlocker
 */

/**
 * @typedef {Array<PlanningBlocker>} PlanningBlockers
 */

// endregion

/**
 * @param {Array} blockers
 * @returns {PlanningBlockers}
 */
export function cleanBlockers(blockers) {
    const out = [];
    if (!blockers.length) {
        return out;
    }

    let currentBlockerStart = blockers[0].start;
    let currentBlockerEnd = blockers[0].end;

    for (let i = 1; i < blockers.length; i++) {
        const blocker = blockers[i];

        if (blocker.start.isAfter(currentBlockerEnd)) {
            out.push({ start: currentBlockerStart, end: currentBlockerEnd });
            currentBlockerStart = blocker.start;
            currentBlockerEnd = blocker.end;
        } else if (blocker.end.isAfter(currentBlockerEnd)) {
            currentBlockerEnd = blocker.end;
        }
    }

    out.push({
        start: currentBlockerStart,
        end: currentBlockerEnd,
    });

    return out;
}

/**
 * @param {moment.Moment} date
 * @param {Array} dirtyBlockers
 * @param {Number} days
 * @returns {Array<Gap>}
 */
export function getGaps(date, dirtyBlockers, days = 1) {
    const blockers = cleanBlockers(dirtyBlockers);

    const endOfPeriod = date
        .clone()
        .endOf('day')
        .add(days - 1, 'days');

    if (!blockers.length) {
        return [{ start: date, duration: endOfPeriod.diff(date, 'minutes') }];
    }

    const gaps = [];
    const firstGap = {
        start: date,
        duration: blockers[0].start.diff(date, 'minutes'),
    };

    if (firstGap.duration > 0) {
        gaps.push(firstGap);
    }

    for (let i = 1; i < blockers.length; i++) {
        let lastEnd = blockers[i - 1].end;
        if (lastEnd.isBefore(date)) {
            lastEnd = date;
        }

        const nextStart = blockers[i].start;
        const duration = nextStart.diff(lastEnd, 'minutes');

        if (duration > 0) {
            gaps.push({ start: lastEnd, duration });
        }
    }

    let lastEndTime = blockers[blockers.length - 1].end;

    if (lastEndTime.isBefore(date)) {
        lastEndTime = date;
    }

    gaps.push({
        start: lastEndTime,
        duration: endOfPeriod.diff(lastEndTime, 'minutes'),
    });

    return gaps;
}

/**
 * Returns a dayIndex => [pipes] map of (fitting) pipes in planned positions
 * @param {Array} pipesToPlan - An array of pipe objects to plan
 * @param {Array} blockers - An array of items that block planning
 * @param {Object} baseDate - The (moment) date to start planning from
 * @param {number} daysToPlan - Amount of days to fully plan
 * @param {number=} dayOffset - Amount of days to skip before starting planning
 * @param {number=} minuteOffset - Amount of minutes to skip before starting planning
 * @param {number=} maxPerDay - Maximum amount of pipes to plan per day
 */
export function planPipes(
    pipesToPlan,
    blockers,
    baseDate,
    daysToPlan,
    dayOffset = 0,
    minuteOffset = 0,
    maxPerDay = 9001,
) {
    const plannedDays = {};

    let pipesLeftToPlan = pipesToPlan.sort((a, b) => {
        const orderTimeDiff = a.order.date.diff(b.order.date);
        if (orderTimeDiff !== 0) {
            return orderTimeDiff;
        }
        return b.duration - a.duration;
    });

    const blockingPipes = blockers
        ? [...blockers].filter((pipe) => !pipe.dropping)
        : [];
    const sortedPipes = blockingPipes.sort((a, b) => a.start.diff(b.start));

    const startOfDate = baseDate.clone().startOf('day');
    const date = startOfDate
        .clone()
        .add(dayOffset, 'days')
        .add(minuteOffset, 'minutes');

    getGaps(date, sortedPipes, daysToPlan).forEach((gap) => {
        let gapStart = gap.start.clone();
        let gapDuration = gap.duration;

        let fittingPipe = pipesLeftToPlan.find(
            (pipe) => pipe.duration <= gapDuration,
        );
        while (fittingPipe) {
            const newPlannedPipe = {
                ...fittingPipe,
                start: gapStart.clone(),
                end: gapStart.clone().add(fittingPipe.duration, 'minutes'),
            };

            const dayIdx = newPlannedPipe.start
                .clone()
                .startOf('day')
                .diff(startOfDate, 'days');

            if (!plannedDays[dayIdx]) {
                plannedDays[dayIdx] = [];
            } else if (plannedDays[dayIdx].length >= maxPerDay) {
                const endOfDay = newPlannedPipe.start
                    .clone()
                    .endOf('day')
                    .add(1, 'minute');
                const minutesToEndOfDay = endOfDay.diff(
                    newPlannedPipe.start,
                    'minutes',
                );

                gapStart = endOfDay.clone();
                gapDuration -= minutesToEndOfDay;
            } else {
                plannedDays[dayIdx].push(newPlannedPipe);

                gapStart = newPlannedPipe.end.clone();
                gapDuration -= fittingPipe.duration;

                // eslint-disable-next-line no-loop-func
                pipesLeftToPlan = pipesLeftToPlan.filter(
                    (p) => p !== fittingPipe,
                );
            }
            // eslint-disable-next-line no-loop-func
            fittingPipe = pipesLeftToPlan.find(
                (pipe) => pipe.duration && pipe.duration <= gapDuration,
            );
        }
    });

    return plannedDays;
}

/**
 * Calculate the blockers for the given action groups. The blockers are generic
 * objects which can be used to plan.
 *
 * @param {ActionGroupAllInfo[]} actionGroups
 *
 * @returns {PlanningBlockers}
 */
const calculateActionGroupsBlockers = (actionGroups) => {
    const out = actionGroups.map((actionGroup) => ({
        start: actionGroup.workOrder.startTime,
        end: actionGroup.workOrder.endTime,
    }));

    out.sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1));

    return out;
};

/**
 * Calculate the gaps from the blockers. The longest duration will be added as
 * the last gap so that it can fit all items.
 *
 * @param {PlanningBlockers} blockers
 * @param {moment.Moment} startTime
 * @param {Number} longestDuration
 *
 * @return {Array<Gap>}
 */
const getActionGroupsGaps = (blockers, startTime, longestDuration) => {
    if (!blockers.length) {
        return [{ start: startTime, duration: longestDuration }];
    }

    const gaps = [];

    const firstGap = {
        start: startTime,
        duration: blockers[0].start.diff(startTime, 'minutes'),
    };

    if (firstGap.duration > 0) {
        gaps.push(firstGap);
    }

    for (let i = 1; i < blockers.length; i++) {
        const lastEnd = blockers[i - 1].end;
        const nextStart = blockers[i].start;
        const duration = nextStart.diff(lastEnd, 'minutes');

        if (duration > 0) {
            gaps.push({ start: lastEnd, duration });
        }
    }

    gaps.push({
        start: blockers[blockers.length - 1].end,
        duration: longestDuration,
    });

    return gaps;
};

/**
 * Calculates the actual duration for the given action group based on work times
 * and overtimes.
 *
 * Work time blockers do not allow any work to be done during that time. Any
 * bubbles that overlap these blockers will get longer.
 *
 * 1. We do not know how efficient the worker will be on the server side (while
 *    calculating durations) if the action is unassigned. So, we can calculate
 *    this duration here, when we know who the assigned worker will be.
 *
 * @param {ActionGroupAllInfo & {
 *  unassigned: boolean
 * }} actionGroup
 * @param {Overtime[]} overtimes - for the correct user
 * @param {moment.Moment} startDate
 * @param {Number} workerTimeFactor - the efficiency of the worker
 *
 * @return {Number}
 */
const calculateActionGroupDuration = (
    actionGroup,
    overtimes,
    startDate,
    workerTimeFactor,
) => {
    let duration = actionGroup.workOrder?.duration ?? actionGroup.duration;

    const factor = actionGroup.unassigned ? workerTimeFactor : 1; /* [1] */
    duration *= factor;

    // Get blockers which can affect the duration of the bubble. They end after
    // the action group starts.
    // 1. Want bubbles that might have started before the start date but end
    //    after the bubble starts
    // 2. Get many blockers into the future in case the bubble is really long
    const blockersStart = startDate.clone().subtract(5, 'days').startOf('day'); // [1]
    const blockersEnd = startDate.clone().add(6, 'months'); // [2]
    const blockers = getWorkTimeBlockers(
        overtimes,
        blockersStart,
        blockersEnd,
    ).filter((b) => b.end.isAfter(startDate));

    const actionGroupEnd = startDate.clone().add(duration, 'minutes');
    let [blocker, ...remainingBlockers] = blockers;

    // Loop through blockers until they can no longer overlap the bubble
    while (blocker.start.isBefore(actionGroupEnd)) {
        let extraDuration = 0;

        if (blocker.start.isAfter(startDate)) {
            // Blocker starts inside the action group bubble
            // 1.
            // BUBBLE        [        ]
            // BLOCKER            #######
            // RESULT        [    =======    ]

            // 2.
            // BUBBLE        [            ]
            // BLOCKER            #####
            // RESULT        [    =====        ]

            extraDuration += blocker.end.diff(blocker.start, 'minutes');
        } else {
            // Blocker starts before the bubble
            // 1.
            // BUBBLE        [        ]
            // BLOCKER    #######
            // RESULT        [===         ]

            // 2.
            // BUBBLE        [     ]
            // BLOCKER     ###########
            // RESULT        [========     ]

            extraDuration += blocker.end.diff(startDate, 'minutes');
        }

        duration += extraDuration;
        actionGroupEnd.add(extraDuration, 'minutes');

        // Since the blockers are sorted chronologically, we can just loop over
        // them in the order they are given
        [blocker, ...remainingBlockers] = remainingBlockers;
    }

    return Math.round(duration);
};

/**
 * Plan the given action groups into the given gaps. Returns a list of action
 * groups with planned work orders. If the action group was previously
 * unplanned, then will create a new "dummy" work order in the action group
 * with no ID.
 *
 * 1. We do not know how efficient the worker will be on the server side (while
 *    calculating durations) if the action is unassigned. So, we can calculate
 *    this duration here, when we know who the assigned worker will be.
 * 2. We round the duration to the nearest minute so that we can keep durations
 *    in the work orders as integers. It is not necessary to have precise
 *    bubble lengths. More precise calculations are done using the backend
 *    formula calculations.
 *
 * @param {Array<ActionGroupAllInfo>} actionGroups
 * @param {Array<Gap>} gaps
 * @param {Array<Overtime>} overtimes
 * @param {Number} workerTimeFactor - worker's time factor. This is BEFORE
 *      taking into account whether the action group is unassigned.
 *
 * @returns {Array<ActionGroupAllInfo>}
 */
const planActionGroupsIntoGaps = (
    actionGroups,
    gaps,
    overtimes,
    workerTimeFactor,
) => {
    const scheduledActionGroups = [];

    let actionGroupsLeftToPlan = actionGroups;
    gaps.forEach((gap) => {
        let gapStart = gap.start.clone();
        let gapDuration = gap.duration;

        /**
         * Calculates the duration of the action group. But since we do not want
         * to calculate the duration multiple times, it is assigned to the
         * `calculatedDuration` property for "caching".
         *
         * @param {ActionGroupAllInfo & {
         *  calculatedDuration?: number
         * }} actionGroup
         *
         * @returns {boolean}
         */
        const isFitting = (actionGroup) => {
            // eslint-disable-next-line no-param-reassign
            actionGroup.calculatedDuration = calculateActionGroupDuration(
                actionGroup,
                overtimes,
                gapStart,
                workerTimeFactor,
            );
            return actionGroup.calculatedDuration <= gapDuration;
        };

        // The first fitting action group
        let fittingActionGroup = actionGroupsLeftToPlan.find(isFitting);
        const isNotCurrent = (g) => g !== fittingActionGroup;
        while (fittingActionGroup) {
            const plannedStart = gapStart.clone();
            const plannedEnd = gapStart
                .clone()
                .add(fittingActionGroup.calculatedDuration, 'minutes');
            const { workOrder } = fittingActionGroup;
            const timeFactor = fittingActionGroup.unassigned /* [1] */
                ? workerTimeFactor
                : 1;

            const newPlannedActionGroup = formatActionGroup({
                ...fittingActionGroup,
                workOrder: {
                    ...workOrder,
                    start_time: plannedStart.format(),
                    end_time: plannedEnd.format(),
                    startTime: plannedStart,
                    endTime: plannedEnd,
                },

                duration: workOrder?.duration
                    ? workOrder.duration
                    : Math.round(
                          fittingActionGroup.duration * timeFactor,
                      ) /* [2] */,
            });

            // Add the new planned action group to the worker's scheduled
            // action groups
            scheduledActionGroups.push(newPlannedActionGroup);

            // Adjust start time and duration for the gap so that the next
            // bubbles don't overlap this one
            gapStart = plannedEnd.clone();
            gapDuration -= fittingActionGroup.calculatedDuration;

            // Filter out the newly planned action group
            actionGroupsLeftToPlan = actionGroupsLeftToPlan.filter(
                isNotCurrent,
            );

            // Find the next fitting action group
            fittingActionGroup = actionGroupsLeftToPlan.find(isFitting);
        }
    });

    return scheduledActionGroups;
};

/**
 * Plan the grouped actions with the given ids. Returns the planned actions
 * grouped by workers.
 *
 * @param {Array<ActionGroupAllInfo>} droppingActionGroups
 * @param {Array<ActionGroupAllInfo>} plannedActionGroups
 * @param {moment.Moment} startDate
 * @param {Number} hoursFromBase
 * @param {Array<Overtime>} overtimes
 * @param {Array<Operator>} operators
 *
 * @return {Object.<Number, ActionGroupAllInfo[]>}
 */
export const planActionGroups = (
    droppingActionGroups,
    plannedActionGroups,
    startDate,
    hoursFromBase,
    overtimes,
    operators,
) => {
    const droppedTime = startDate.clone().add(hoursFromBase, 'hours');

    const workerIds = [...new Set(droppingActionGroups.map((g) => g.workerId))];
    const droppingIds = droppingActionGroups.map((g) => g.id);

    const workersSchedule = workerIds.reduce(
        (res, id) => ({ ...res, [id]: [] }),
        {},
    );
    workerIds.forEach((workerId) => {
        const worker = operators[workerId];

        const workerDroppingActionGroups = droppingActionGroups.filter(
            (g) => g.workerId === workerId,
        );

        const workerPlannedActionGroups = plannedActionGroups
            .filter((b) => b.workerId === workerId)
            .filter((b) => !droppingIds.includes(b.id))
            .filter((b) => b.workOrder.endTime.isAfter(droppedTime));

        const gaps = getActionGroupsGaps(
            calculateActionGroupsBlockers(workerPlannedActionGroups),
            droppedTime,
            6 * 30 * 24 * 60, // 6 months in minutes
        );

        workersSchedule[workerId] = planActionGroupsIntoGaps(
            workerDroppingActionGroups,
            gaps,
            overtimes.filter((o) => o.worker === workerId),
            worker.time_factor,
        );
    });

    return workersSchedule;
};

export function insertPipe(
    state,
    pipeId,
    startDate,
    highlightedOrder = null,
    dropping = false,
    target = null,
) {
    const pipe = { ...state.pipes[pipeId] };

    pipe.id = pipeId;

    const clientId = pipe.client;
    pipe.client = state.clients[clientId];
    pipe.client.id = clientId;

    const orderId = pipe.order;
    pipe.order = state.orders[orderId];
    pipe.order.id = orderId;

    pipe.selected = state.tree.selectedLeaves.includes(pipeId);
    pipe.highlighted = highlightedOrder === orderId;
    pipe.dropping = state.droppingPipes.includes(pipeId);

    if (dropping) {
        target.push(pipe);
    } else {
        const startDay = pipe.start
            .clone()
            .startOf('day')
            .diff(startDate, 'days');

        if (!target[startDay]) {
            target[startDay] = []; // eslint-disable-line
        }

        target[startDay].push(pipe);
    }
}

export const calculateEndDate = (startDate) =>
    startDate.clone().add(30, 'days').endOf('day');

export function getBlockerDays(
    blockers,
    startDate,
    endDate,
    selectedBlockerIds = null,
) {
    const blockerDays = {};

    // returns array of indices for enabled weekdays
    const parseMask = (mask) =>
        Array(7)
            .fill()
            .map((el, idx) => (((mask >> idx) & 1) > 0 ? idx : null)) // eslint-disable-line no-bitwise
            .filter((x) => x !== null);

    const activeBlockers = Object.values(blockers).filter(
        (blocker) =>
            (moment(blocker.period_start).isBetween(
                startDate,
                endDate,
                null,
                '[)',
            ) ||
                moment(blocker.period_end).isBetween(
                    startDate,
                    endDate,
                    null,
                    '[)',
                ) ||
                startDate.isBetween(
                    moment(blocker.period_start),
                    moment(blocker.period_end),
                    null,
                    '[)',
                )) &&
            blocker.weekdays > 0,
    );

    const weekdayBlockers = {};
    activeBlockers.forEach((blocker) =>
        parseMask(blocker.weekdays).forEach((weekday) => {
            if (!(weekday in weekdayBlockers)) {
                weekdayBlockers[weekday] = [];
            }
            weekdayBlockers[weekday].push(blocker);
        }),
    );

    const daysInPeriod = endDate.diff(startDate, 'days');
    Array(daysInPeriod)
        .fill()
        .forEach((_, dayIdx) => {
            const date = startDate.clone().startOf('day').add(dayIdx, 'days');

            const weekdayIdx = date.isoWeekday() - 1;
            if (!(weekdayIdx in weekdayBlockers)) {
                return;
            }

            weekdayBlockers[weekdayIdx]
                .filter(({ period_start: start, period_end: end }) =>
                    date.isBetween(moment(start), moment(end), null, '[)'),
                )
                .forEach((blocker) => {
                    if (!(dayIdx in blockerDays)) {
                        blockerDays[dayIdx] = [];
                    }

                    const blockerObj = {
                        ...blocker,
                        start: date
                            .clone()
                            .add(blocker.start_minute, 'minutes'),
                        end: date
                            .clone()
                            .add(
                                blocker.start_minute + blocker.duration,
                                'minutes',
                            ),
                    };
                    if (selectedBlockerIds) {
                        blockerObj.selected = selectedBlockerIds.includes(
                            blocker.id,
                        );
                    }

                    blockerDays[dayIdx].push(blockerObj);
                });
        });

    return blockerDays;
}

/**
 * Flatten the blockers gotten from getBlockerDays. This is because we don't
 * need to group blockers by days but we can just loop over them in Parts
 * timeplan.
 *
 * @return {Object.<Array, Object>}
 */
export const getPartsBlockersByOperator = (
    blockers,
    startDate,
    endDate,
    selectedBlockerIds = [],
) => {
    const blockerDays = getBlockerDays(
        blockers,
        startDate,
        endDate,
        selectedBlockerIds,
    );

    const blockersByOperator = {};
    Object.values(blockerDays).forEach((blockersByDay) => {
        blockersByDay.forEach((blocker) => {
            blocker.operators.forEach((operatorId) => {
                if (!(operatorId in blockersByOperator)) {
                    blockersByOperator[operatorId] = [];
                }
                blockersByOperator[operatorId].push(blocker);
            });
        });
    });

    return blockersByOperator;
};

export default null;
