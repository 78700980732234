import { connect } from 'react-redux';

import { getErrors, getIsLoading } from 'core/ducks/common';
import {
    workOrdersRoutine,
    workOrdersSelectors,
} from 'parts/workplan/ducks/workOrders';

import { partProductsRoutine } from '../ducks/partProducts';
import { timeTrackersRoutine } from '../ducks/timeTrackers';
import { downtimesRoutine } from '../ducks/downtimes';
import { actionsRoutine } from '../ducks/actions';

import {
    operationsPageRoutine,
    STATE_KEY as PART_OPERATIONS_STATE_KEY,
} from '../sagas/operationsViewSaga';
import { PartsOperationsList } from './PartsOperationsList';

const mapStateToProps = (state) => ({
    workOrderIds: workOrdersSelectors.getList(state).map((wo) => wo.id),
    isLoading: getIsLoading(state, [PART_OPERATIONS_STATE_KEY]),
    error: getErrors(state, [PART_OPERATIONS_STATE_KEY]),
});

const mapDispatchToProps = (dispatch) => ({
    triggerFetchingViewData: () => dispatch(operationsPageRoutine.trigger()),
    reInitOperationsPageState: () => {
        // reset state to initial is used before using filtration
        // as otherwise results will be just merged
        dispatch(workOrdersRoutine.initialize());
        dispatch(partProductsRoutine.initialize());
        dispatch(timeTrackersRoutine.initialize());
        dispatch(downtimesRoutine.initialize());
        dispatch(actionsRoutine.initialize());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PartsOperationsList);
