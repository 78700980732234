import createEntityDuck from 'core/ducks/generic/entity';
import { operationsPageRoutine } from 'parts/operations/sagas/operationsViewSaga';

export const STATE_KEY = 'partProducts';

const [baseReducer, partProductsRoutine, selectors] = createEntityDuck(
    STATE_KEY,
);

const partProductsReducer = (currentState = undefined, action) => {
    // First evaluate the base reducer
    const state = baseReducer(currentState, action);

    switch (action.type) {
        case operationsPageRoutine.SUCCESS:
            return baseReducer(
                state,
                partProductsRoutine.success({
                    byId: action.payload.partProducts,
                }),
            );

        default:
            return state;
    }
};

const partProductsSelectors = {
    ...selectors,
};

export default partProductsReducer;
export { partProductsRoutine, partProductsSelectors };
