import moment from 'moment';

export default function () {
    const ranges = {};
    const today = gettext('Today');
    const lastSevenDays = gettext('Last 7 Days');
    const lastThirtyDays = gettext('Last 30 Days');
    const thisMonth = gettext('This Month');
    const lastMonth = gettext('Last Month');
    ranges[today] = [moment(), moment()];
    ranges[lastSevenDays] = [moment().subtract(6, 'days'), moment()];
    ranges[lastThirtyDays] = [moment().subtract(29, 'days'), moment()];
    ranges[thisMonth] = [moment().startOf('month'), moment().endOf('month')];
    ranges[lastMonth] = [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
    ];
    return ranges;
}
