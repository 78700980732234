import { createRoutine } from 'redux-saga-routines';

import createEntityDuck from 'core/ducks/generic/entity';
import { operationsPageRoutine } from 'parts/operations/sagas/operationsViewSaga';

/**
 * @typedef {object} DowntimeNormalized
 * @property {number} id
 * @property {number} work_order
 */

export const STATE_KEY = 'downtimes';

export const affixDowntimeRoutine = createRoutine('affixDowntimeRoutine');
export const solveDowntimeRoutine = createRoutine('solveDowntimeRoutine');

const [baseReducer, downtimesRoutine, selectors] = createEntityDuck(STATE_KEY);

const downtimesReducer = (currentState = undefined, action) => {
    const state = baseReducer(currentState, action);

    switch (action.type) {
        case operationsPageRoutine.SUCCESS:
            return baseReducer(
                state,
                downtimesRoutine.success({
                    byId: action.payload.downtimes ?? {},
                }),
            );
        case affixDowntimeRoutine.SUCCESS:
            // Update a single downtime entity in the Redux store
            return baseReducer(
                state,
                downtimesRoutine.success({
                    byId: {
                        [action.payload.downtime.id]: action.payload.downtime,
                    },
                }),
            );
        case solveDowntimeRoutine.SUCCESS:
            return baseReducer(state, downtimesRoutine.delete(action.payload));
        default:
            return state;
    }
};

const downtimesSelectors = {
    ...selectors,
    getByWorkOrderId: (state, workOrderId) =>
        selectors
            .getList(state)
            .find((downtime) => downtime.work_order === workOrderId),
};

export default downtimesReducer;
export { downtimesRoutine, downtimesSelectors };
