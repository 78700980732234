export const FILTER_QUERY = 'filters/FILTER_QUERY';
export const FILTER_SET = 'filters/FILTER_SET';
export const FILTER_AUTO_SET = 'filters/FILTER_AUTO_SET';
export const FILTER_DATE_RANGE = 'filters/FILTER_DATE_RANGE';
export const FILTER_STATUS = 'filters/FILTER_STATUS';
export const FILTER_TOGGLE = 'filters/FILTER_TOGGLE';

const initialState = {
    queryString: '',
    startDate: null,
    endDate: null,
    statusQuery: null,
    querySet: {},
    filterBarKey: 1,
};

function get_default_filter_object(query) {
    // JS version of python function of the same name
    let counter = 0;
    let inputKey;
    const inputs = [];
    const filterSet = {};

    Object.entries(query).forEach(([filterName, filters]) => {
        filters.forEach((filterObj) => {
            inputKey = `input-${counter}`;
            inputs.push(inputKey);
            filterSet[inputKey] = { [filterName]: filterObj };
            counter += 1;
        });
    });

    return {
        counter,
        inputs,
        filterSet,
        query,
    };
}

// always combine as listFilters
export default function (state = initialState, action) {
    switch (action.type) {
        case FILTER_QUERY:
            return { ...state, queryString: action.query.toLowerCase() };
        case FILTER_SET:
            return { ...state, querySet: action.querySet };
        case FILTER_AUTO_SET:
            // Extract auto-filters from the current state's querySet.
            // We need this to later copy "disabled" attribute from current state of auto-filters
            // to the new auto-filters that are received from websocket. We do so because the
            // backend does not know on disabled state of auto-filters and does not need to know.
            // In: { fieldName: [{auto_filter: true, disabled: true, value: 'A'}, {auto_filter: false, value: 'B'}]}
            // Out: { fieldName: [{auto_filter: true, disabled: true, value: 'A'}]}
            // eslint-disable-next-line no-case-declarations
            const currentAutoFiltersQS = Object.entries(state.querySet).reduce(
                (acc, [key, filters]) => {
                    const autoFilters = filters.filter(
                        (filter) => filter.auto_filter,
                    );
                    if (autoFilters.length) {
                        acc[key] = autoFilters;
                    }
                    return acc;
                },
                {},
            );
            // Merge current state querySet with autoFilterQuerySet from action
            // 1. Extract all unique filter field names from the state and the action
            // In: state = { fieldName1: [{value: 'A'}]} action_payload = {fieldName2: [{value: 'B', auto_filter: true}]}
            // Out: ["fieldName1", "fieldName2"]
            // eslint-disable-next-line no-case-declarations
            const allFilterFieldNames = Array.from(
                new Set(
                    Object.keys(state.querySet).concat(
                        Object.keys(action.autoQuerySet),
                    ),
                ),
            );
            // eslint-disable-next-line no-case-declarations
            const querySet = {};
            // 2. For each key, get its corresponding autoQuerySet values from the action
            // and append the `disabled` property if present in currentAutoFiltersQS.
            allFilterFieldNames.forEach((filterFieldName) => {
                // 2.1. Select the list of filters for a auto filter field
                // ie: [{auto_filter: true, operator: "equals", disabled: true, value: 'B'}]
                const currentAutoFilterLookup =
                    currentAutoFiltersQS[filterFieldName] || [];

                // 2.2. Copy over disabled status for each auto filter,
                // currently we should only have one auto filter per field, but in case we have more,
                // then the logic is that we match the ordering of the filters...
                const autoQuerySetFilterFieldValues = (
                    action.autoQuerySet[filterFieldName] || []
                ).map((filterValue, index) => {
                    const modifiedFilterValue = { ...filterValue };
                    const currentFilterVal = currentAutoFilterLookup[index];
                    if (currentFilterVal) {
                        // Currently only disabled, but this is flexible to add more fields if needed
                        modifiedFilterValue.disabled =
                            currentFilterVal.disabled;
                    }
                    return modifiedFilterValue;
                });

                // 3. Construct the new querySet by merging the non-auto_filter entries
                // from the current state's querySet
                // and the autoQuerySet values from the action.
                querySet[filterFieldName] = (
                    state.querySet[filterFieldName] || []
                )
                    .filter((filter) => !filter.auto_filter)
                    .concat(autoQuerySetFilterFieldValues);
                // 4. Clean up: If for some reason, a key has no values, remove it.
                if (!querySet[filterFieldName].length) {
                    delete querySet[filterFieldName];
                }
            });
            // Apply a horrible, horrible hack
            DJ_CONST.DEFAULT_FILTERS = get_default_filter_object(querySet);
            return { ...state, querySet, filterBarKey: state.filterBarKey + 1 };
        case FILTER_DATE_RANGE:
            return {
                ...state,
                endDate: action.endDate,
                startDate: action.startDate,
            };
        case FILTER_STATUS:
            return { ...state, statusQuery: action.statusQuery };
        case FILTER_TOGGLE:
            // eslint-disable-next-line no-case-declarations
            const qsCopy = { ...state.querySet };
            Object.entries(qsCopy).forEach(([filterKey, filterValue]) => {
                if (filterKey !== action.fieldName) {
                    return;
                }
                filterValue.forEach((filter, index) => {
                    if (
                        filter.operator === action.operator &&
                        filter.value === action.value
                    ) {
                        qsCopy[filterKey][index].disabled =
                            typeof qsCopy[filterKey][index].disabled !==
                            'undefined'
                                ? !qsCopy[filterKey][index].disabled
                                : true;
                    }
                });
            });
            return { ...state, querySet: qsCopy };
        default:
            return state;
    }
}

export const filterByQueryString = (query) => ({
    type: FILTER_QUERY,
    query,
});

export const filterByFilterSet = (querySet) => ({
    type: FILTER_SET,
    querySet,
});

export const filterByAutoFilterSet = (autoQuerySet) => ({
    type: FILTER_AUTO_SET,
    autoQuerySet,
});

export const filterByDateRange = (startDate, endDate) => ({
    type: FILTER_DATE_RANGE,
    startDate,
    endDate,
});

export const filterByStatus = (statusQuery) => ({
    type: FILTER_STATUS,
    statusQuery,
});

export const toggleFilterDisable = (fieldName, operator, value) => ({
    type: FILTER_TOGGLE,
    fieldName,
    operator,
    value,
});

export const selectFilterQueryArgs = (state) => {
    const { startDate, endDate, queryString, querySet } = state.listFilters;
    const fetchQuery = {};
    if (startDate) {
        fetchQuery.start_date = startDate;
    }
    if (endDate) {
        fetchQuery.end_date = endDate;
    }
    if (queryString) {
        fetchQuery.query_string = queryString;
    }
    if (Object.keys(querySet).length) {
        fetchQuery.filter_cluster = JSON.stringify(
            Object.entries(querySet).reduce((acc, [filterKey, filterValue]) => {
                acc[filterKey] = filterValue.filter(
                    (filter) => !filter.disabled,
                );
                return acc;
            }, {}),
        );
    }
    return fetchQuery;
};
