import PropTypes from 'prop-types';
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import momentPropTypes from 'react-moment-proptypes';
import locale from '../../utils/dateLocalization';

import dateFilterRanges from '../../utils/dateFilterRanges';

const DateFilter = ({ onChange, startDate, endDate, onClearFilter }) => {
    const ranges = dateFilterRanges();
    let label = gettext('All dates');
    if (startDate !== null && endDate !== null) {
        if (!startDate.isSame(endDate, 'date')) {
            label = `${startDate.format('DD.MM.YYYY')} - ${endDate.format(
                'DD.MM.YYYY',
            )}`;
        } else {
            label = startDate.format('DD.MM.YYYY');
        }
    }
    const isResetVisible = !!startDate || !!endDate;

    return (
        <div className="date-range-picker-wrapper">
            <DateRangePicker locale={locale} onApply={onChange} ranges={ranges}>
                <span className="selected-date-range-btn">{label}</span>
            </DateRangePicker>
            {isResetVisible ? (
                <a className="reset-date-filter" onClick={onClearFilter}>
                    {gettext('Remove date filter')}
                </a>
            ) : null}
        </div>
    );
};

DateFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClearFilter: PropTypes.func.isRequired,
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
};

export default DateFilter;
