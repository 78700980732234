import { gettext } from 'core/utils/text';

const locale = {
    format: 'DD.MM.YYYY',
    separator: ' - ',
    applyLabel: gettext('Apply'),
    cancelLabel: gettext('Cancel'),
    fromLabel: gettext('From'),
    toLabel: gettext('To'),
    customRangeLabel: gettext('Custom'),
    // DateRangePicker requires Sunday as the first day of the week
    daysOfWeek: [
        gettext('Su'),
        gettext('Mo'),
        gettext('Tu'),
        gettext('We'),
        gettext('Th'),
        gettext('Fr'),
        gettext('Sa'),
    ],
    // threeLetterDays isn't used in DateRangePicker
    threeLetterDays: {
        Mon: gettext('Mon'),
        Tue: gettext('Tue'),
        Wed: gettext('Wed'),
        Thu: gettext('Thu'),
        Fri: gettext('Fri'),
        Sat: gettext('Sat'),
        Sun: gettext('Sun'),
    },
    monthNames: [
        gettext('January'),
        gettext('February'),
        gettext('March'),
        gettext('April'),
        gettext('May'),
        gettext('June'),
        gettext('July'),
        gettext('August'),
        gettext('September'),
        gettext('October'),
        gettext('November'),
        gettext('December'),
    ],
    firstDay: 1,
};

export default locale;
