import {
    formatComponentLabel,
    getComponentPostfix,
} from 'parts/workplan/utils/formatting';
import {
    actionWorkPlanPath,
    cuttingAction,
    expansionAction,
    needsCutting,
    needsExpansion,
} from 'parts/utils/parts';

/**
 * Handle toggling of one tree item.
 *
 * 1. There can only ever be one tree item of a specific type open
 *    (similarly to the TreeView in parts timeplan).
 *
 * @param {TreeItem} treeItem
 * @param {{component: String[], part: String[]}} openItems
 */
export const toggleOpenTreeItem = (treeItem, openItems) => ({
    ...openItems,
    [treeItem.type]: treeItem.expanded ? [] : [treeItem.id] /* 1 */,
});

/**
 * Map the given work plan (with the given open items conf) to TreeItem
 * objects.
 *
 * @param {WorkPlan} workPlan
 * @param {{component: String[], part: String[]}} openItems
 *
 * @return {TreeItem[]}
 */
export const mapWorkPlanToTreeItems = (workPlan, openItems) => {
    const mapActionToTreeItem = (action, component, part) => ({
        id: action.id,
        label: action.action_name,
        type: 'action',
        workPlanPath: actionWorkPlanPath(action, part, component, {
            work_plan_json: workPlan,
        }),
    });

    return workPlan.map((component, componentIndex) => {
        // region === Parts

        const parts = component.parts.map((part, partIndex) => {
            const actions = part.actions.map((action) =>
                mapActionToTreeItem(action, component, part),
            );

            const partId = `${part.id}__${partIndex}`;
            return {
                id: partId,
                label: part.part_name,
                odl: part.OD_L,
                comment: part.comment,
                type: 'part',
                expanded: openItems.part.includes(partId),
                children: actions,
                numberOfChildren: actions.length,
            };
        });

        // endregion

        // region === Body actions

        const bodyActions = [];
        if (needsCutting(component)) {
            bodyActions.push(
                mapActionToTreeItem(
                    cuttingAction(component.cutting_operator),
                    component,
                ),
            );
        }
        if (needsExpansion(component)) {
            bodyActions.push(
                mapActionToTreeItem(
                    expansionAction(component.expansion_operator),
                    component,
                ),
            );
        }

        // endregion

        const children = [...parts, ...bodyActions];
        const componentId = `${component.type}__${componentIndex}`;
        return {
            id: componentId,
            label: formatComponentLabel(
                component,
                getComponentPostfix(workPlan, componentIndex),
            ),
            type: 'component',
            expanded: openItems.component.includes(componentId),
            comment: component.comment,
            component_tags: component.component_tags || [],
            children,
            numberOfChildren: children.reduce(
                (sum, item) => sum + (item.numberOfChildren || 1),
                0,
            ),
        };
    });
};
