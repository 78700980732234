import { Resource } from 'tg-resources';

import { calculateEndDate } from 'core/timeplan/utils/planning';
import { getCookie } from 'core/utils/cookie';
import { setStateHash } from 'core/timeplan/ducks/stateHash';

export const SELECT_PIPES = 'timeplan/SELECT_PIPES';
export const TOGGLE_TREE_ITEM = 'timeplan/TOGGLE_TREE_ITEM';
export const CLEAR_SELECTED = 'timeplan/CLEAR_SELECTED';

export const SET_DROPPING = 'timeplan/SET_DROPPING';
export const DROP_PIPES = 'timeplan/DROP_PIPES';
export const SELECT_DATE = 'timeplan/SELECT_DATE';
export const SELECT_TAB = 'timeplan/SELECT_TAB';
export const SET_COLLAPSED = 'timeplan/SET_COLLAPSED';
export const UNPLAN_PIPES = 'timeplan/UNPLAN_PIPES';

export const RECEIVE_PIPES = 'timeplan/RECEIVE_PIPES';
export const REMOVE_PIPES = 'timeplan/REMOVE_PIPES';
export const RECEIVE_ORDERS = 'timeplan/RECEIVE_ORDERS';

export const SET_ERROR = 'timeplan/SET_ERROR';
export const SET_STATE_HASH = 'timeplan/SET_STATE_HASH';
export const SET_RECENT_PIPES = 'timeplan/SET_RECENT_PIPES';

export const SET_LOADING = 'timeplan/SET_LOADING';

export const setLoading = (loading) => ({ type: SET_LOADING, loading });

export const selectPipes = (ids, ctrl) => ({
    type: SELECT_PIPES,
    ids,
    ctrl,
});

export const toggleTreeItem = (item) => ({
    type: TOGGLE_TREE_ITEM,
    item,
});

export const collectItemIds = (item) => {
    const ids = [];

    const addIds = (node) => {
        if (
            !node.children?.length &&
            (!node.warning ||
                node.quantity > Number(DJ_CONST.MAX_SINGLE_PIPE_LENGTH))
        ) {
            ids.push(node.id);
        } else if (node.children?.length) {
            node.children.forEach(addIds);
        }
    };

    addIds(item);

    return ids;
};

export const clearSelected = () => ({
    type: CLEAR_SELECTED,
});

export const setDropping = (pipes) => ({
    type: SET_DROPPING,
    pipes,
});

export const dropPipes = (pipes, allowUndo = false) => ({
    type: DROP_PIPES,
    pipes,
    allowUndo,
});

export const selectDate = (isoDate) => ({
    type: SELECT_DATE,
    date: isoDate,
});

export const selectTab = (tabName) => ({
    type: SELECT_TAB,
    tabName,
});

export const setCollapsed = (collapsed) => ({
    type: SET_COLLAPSED,
    collapsed,
});

export const receiveOrders = (results) => ({
    type: RECEIVE_ORDERS,
    results,
});

export const receivePipes = (pipes) => ({
    type: RECEIVE_PIPES,
    pipes,
});

export const removePipes = (pipeIds) => ({
    type: REMOVE_PIPES,
    pipeIds,
});

export const setError = (statusCode, message) => ({
    type: SET_ERROR,
    statusCode,
    message,
});

export const fetchOrders = () => async (dispatch, getState) => {
    const options = {
        headers: () => ({
            accept: 'application/json',
        }),
    };
    const { startDate } = getState();
    const endDate = calculateEndDate(startDate);
    const fetchQuery = {
        // Status filtering done in BE PipeTimeplanOrdersViewSet
        date: startDate.toISOString(),
        end_date: endDate.toISOString(),
    };

    const getOrders = new Resource(DJ_CONST.order_list_url, options);
    let response = null;
    dispatch(setLoading(true));

    try {
        response = await getOrders.fetch(null, fetchQuery);
    } catch (err) {
        dispatch(
            setError(
                err.statusCode,
                err.isValidationError ? err.firstError(true) : null,
            ),
        );
        dispatch(setLoading(false));
        return;
    }

    if (response) {
        dispatch(receiveOrders(response.results));
        dispatch(setLoading(false));
    }
};

export const setRecentPipes = (pipes) => ({ type: SET_RECENT_PIPES, pipes });

export const requestDropPipes = (pipes, allowUndo = false) => async (
    dispatch,
    getState,
) => {
    const csrfToken = getCookie('csrftoken');
    const options = {
        headers: () => ({
            'X-CSRFToken': csrfToken,
            accept: 'application/json',
        }),
    };

    const postData = pipes.map((pipe) => ({
        id: pipe.id,
        start_time: pipe.start.toISOString(),
    }));

    const planResource = new Resource(DJ_CONST.product_plan_url, options);
    let response = null;

    dispatch(setLoading(true));

    try {
        response = await planResource.post(null, postData, {
            state_hash: getState().stateHash,
        });
    } catch (err) {
        dispatch(
            setError(
                err.statusCode,
                err.isValidationError ? err.firstError(true) : null,
            ),
        );
        dispatch(setLoading(false));
        dispatch(clearSelected());
        dispatch(setDropping([]));
        return;
    }

    dispatch(setStateHash(response.state_hash));
    dispatch(setRecentPipes(allowUndo ? response.data.map((p) => p.id) : []));
    dispatch(receivePipes(response.data));
    dispatch(setLoading(false));
};

export const unplanPipes = (pipes) => ({
    type: UNPLAN_PIPES,
    pipes,
});

export const requestUnplanPipes = (pipes) => async (dispatch, getState) => {
    if (getState().isReadOnly) {
        // disable planning when readOnly
        return;
    }

    const csrfToken = getCookie('csrftoken');
    const options = {
        headers: () => ({
            'X-CSRFToken': csrfToken,
            accept: 'application/json',
        }),
    };

    const postData = pipes.map((pipe) => pipe.id);

    const unplanResource = new Resource(DJ_CONST.product_unplan_url, options);
    let response = null;

    dispatch(setLoading(true));

    try {
        response = await unplanResource.post(null, postData, {
            state_hash: getState().stateHash,
        });
    } catch (err) {
        dispatch(
            setError(
                err.statusCode,
                err.isValidationError ? err.firstError(true) : null,
            ),
        );
        dispatch(setLoading(false));
        return;
    }

    dispatch(setStateHash(response.state_hash));
    dispatch(unplanPipes(response.data));
    dispatch(receivePipes(response.data));
    dispatch(setLoading(false));
};

export const undoPlanPipes = () => (dispatch, getState) => {
    const state = getState();
    const pipes = state.tree.recentPipes.map((id) => ({
        ...state.pipes[id],
        id,
    }));
    dispatch(requestUnplanPipes(pipes));
};

export const unplanSelectedPipes = () => (dispatch, getState) => {
    const state = getState();
    const pipes = state.tree.selectedLeaves.map((id) => ({
        ...state.pipes[id],
        id,
    }));
    dispatch(requestUnplanPipes(pipes));
};
