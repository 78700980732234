import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        queryString: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { input: this.props.queryString };
        this.update = () => this.props.onChange(this.state.input);
    }

    render() {
        return (
            <input
                className="search-bar"
                value={this.state.input}
                onChange={(event) =>
                    this.setState({ input: event.target.value }, this.update)
                }
                placeholder={gettext('Search')}
            />
        );
    }
}
