import * as React from 'react';

export default function nl2br(text: string): React.ReactNode[] {
    const res: React.ReactNode[] = [];
    text.split('\n').forEach((x, i) => {
        if (i !== 0) {
            res.push(<br key={`br-${i}`} />); // eslint-disable-line react/no-array-index-key
        }

        res.push(x);
    });

    return res;
}

export const gettext: typeof django.gettext = (msgid) => django.gettext(msgid);

export const pgettext: typeof django.pgettext = (context, msgid) =>
    django.pgettext(context, msgid);

export const ngettext: typeof django.ngettext = (singular, plural, count) =>
    django.ngettext(singular, plural, count);

export const interpolate: typeof django.interpolate = (fmt, obj, named) =>
    django.interpolate(fmt, obj, named);
