import { combineReducers } from 'redux';

import api from 'core/utils/api';
import { WORK_TYPE_EL, WORK_TYPE_MT } from 'parts/workplan/constants';

// region =========== Type definitions ===========

/**
 * @typedef {Object} Operator
 * @property {Number} id
 * @property {String} email
 * @property {String} name
 * @property {Number} employee_number
 * @property {Number} time_factor
 */

/**
 * @typedef {Object<String|Number, Operator>} OperatorsState
 */

/**
 * @typedef {Object} OperatorsRootState
 * @property {Boolean} isLoading
 * @property {OperatorsState} operators
 */

// endregion

// region =========== Action types ===========

export const SET_OPERATORS = 'operators/RECEIVE_OPERATORS';
const SET_LOADING = 'operators/SET_LOADING';

// endregion

/**
 * @param {OperatorsState} state
 * @param action
 * @returns {OperatorsState}
 */
const operatorsReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_OPERATORS:
            return action.operators;

        default:
            return state;
    }
};

/**
 * @param {Boolean} state
 * @param action
 * @returns {Boolean}
 */
const isLoadingReducer = (state = false, action) => {
    switch (action.type) {
        case SET_LOADING:
            return action.loading;

        default:
            return state;
    }
};

export default combineReducers({
    operators: operatorsReducer,
    isLoading: isLoadingReducer,
});

// region =========== Actions ===========

/**
 * @param {OperatorsState} operators
 * @returns {{type: string, operators: *}}
 */
export const setOperators = (operators) => ({ type: SET_OPERATORS, operators });
/**
 * @param {Boolean} loading
 * @returns {{type: string, loading: *}}
 */
const setLoading = (loading) => ({ type: SET_LOADING, loading });

/**
 * Fetch operators that belong to the given group. Can be PART_OPERATOR, for
 * example.
 *
 * @returns {Function}
 */
export const fetchOperators = () => async (dispatch) => {
    dispatch(setLoading(true));

    let response = null;
    try {
        response = await api.operatorsList.fetch(null);
    } catch (err) {
        dispatch(setLoading(false));
        console.error(err);
        return;
    }

    const operators = response.reduce(
        (result, operator) => ({ ...result, [operator.id]: operator }),
        {},
    );

    dispatch(setOperators(operators));
    dispatch(setLoading(false));
};

// endregion

// region =========== Selectors ===========

/**
 * Get operator by its id.
 * @param {OperatorsRootState} state
 * @param {Number|String} id
 * @returns {Operator}
 */
export const getOperator = (state, id) => state.operators[id];

/**
 * Get an operator by their employee number. Will return `undefined` if there is
 * no operator with the given number.
 * @param {OperatorsRootState} state
 * @param {number|null} number
 * @return {Operator|undefined}
 */
export const getOperatorByEmployeeNumber = (state, number) => {
    if (!number) return undefined;
    return Object.values(state.operators).find(
        (operator) => operator.employee_number === number,
    );
};

/**
 * Get all operators.
 * @param {OperatorsRootState} state
 * @returns {OperatorsState}
 */
export const getAllOperators = (state) => state.operators;

/**
 * Get the label for an operator with the given id.
 * @param {OperatorsRootState} state
 * @param {Number} id
 * @returns {String}
 */
export const getOperatorLabel = (state, id) => {
    const operator = getOperator(state, id);
    return id
        ? `${operator.name} - ${operator.employee_number}`
        : gettext('Unassigned');
};

/**
 * Get the default EL worker based on the employee number saved in DJ_CONST.
 * @param {OperatorsRootState} state
 * @return {Operator|undefined}
 */
export const getDefaultElOperator = (state) => {
    const elEmployeeNumber = DJ_CONST.DEFAULT_EL_WORKER.length
        ? parseInt(DJ_CONST.DEFAULT_EL_WORKER, 10)
        : null;
    return getOperatorByEmployeeNumber(state, elEmployeeNumber);
};

/**
 * Get the default MT worker based on the employee number saved in DJ_CONST.
 * @param {OperatorsRootState} state
 * @return {Operator|undefined}
 */
export const getDefaultMtOperator = (state) => {
    const mtEmployeeNumber = DJ_CONST.DEFAULT_MT_WORKER.length
        ? parseInt(DJ_CONST.DEFAULT_MT_WORKER, 10)
        : null;
    return getOperatorByEmployeeNumber(state, mtEmployeeNumber);
};

/**
 * Get the default operators based on work types. It is possible to set the
 * default worker for some work types in Django admin (constance).
 * @param {OperatorsRootState} state
 * @return {Object.<String, Operator>}
 */
export const getDefaultOperatorsByWorkType = (state) => {
    const elWorker = getDefaultElOperator(state);
    const mtWorker = getDefaultMtOperator(state);
    const defaultOperators = {};
    if (elWorker) defaultOperators[WORK_TYPE_EL] = elWorker;
    if (mtWorker) defaultOperators[WORK_TYPE_MT] = mtWorker;

    return defaultOperators;
};

// endregion
