import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';

import {
    STATE_KEY as STATIONS_KEY,
    stationsRoutine,
} from 'stations/ducks/stations';
import {
    STATE_KEY as SUBSCRIPTIONS_KEY,
    subscriptionsRoutine,
} from 'stations/ducks/subscriptions';
import {
    STATE_KEY as SUBSCRIBERS_KEY,
    subscribersRoutine,
} from 'stations/ducks/subscribers';
import api from 'core/utils/api';

export const subscriptionSchema = new schema.Entity(SUBSCRIPTIONS_KEY, {
    subscriber: new schema.Entity(
        SUBSCRIBERS_KEY,
        {},
        {
            processStrategy: (entity, parent) => ({
                ...entity,
                subscriptionId: parent.id,
            }),
        },
    ),
    stations: [new schema.Entity(STATIONS_KEY)],
});

function* fetchSubscriptionsAsync() {
    const cursorLinks = yield select(
        (state) => state.entities[SUBSCRIPTIONS_KEY].cursor,
    );
    const cursor = cursorLinks.next
        ? new URL(cursorLinks.next).searchParams.get('cursor')
        : '';

    try {
        const { results, ...newCursor } = yield call(() =>
            api.stations.subscriptionsList.fetch(null, { cursor }),
        );
        const normalized = normalize(results, [subscriptionSchema]);

        const getEntities = (key, orderedIds = null) => ({
            byId: normalized.entities[key] || {},
            allIds: orderedIds,
        });

        // List of correctly ordered subscribers
        const subscribersOrder = normalized.result.map(
            (id) => normalized.entities[SUBSCRIPTIONS_KEY][id].subscriber,
        );

        yield all([
            put(subscriptionsRoutine.updateCursor(newCursor)),
            put(stationsRoutine.success(getEntities(STATIONS_KEY))),
            put(
                subscribersRoutine.success(
                    getEntities(SUBSCRIBERS_KEY, subscribersOrder),
                ),
            ),
            put(
                subscriptionsRoutine.success(
                    getEntities(SUBSCRIPTIONS_KEY, normalized.result),
                ),
            ),
        ]);
    } catch (error) {
        yield put(subscriptionsRoutine.failure(error));
    }
}

export function* watchFetchSubscriptions() {
    yield takeEvery(subscriptionsRoutine.REQUEST, fetchSubscriptionsAsync);
}
