import createEntityDuck from 'core/ducks/generic/entity';

export const STATE_KEY = 'subscribers';

const [subscribersReducer, subscribersRoutine, selectors] = createEntityDuck(
    STATE_KEY,
);

const subscribersSelectors = {
    ...selectors,
};

export default subscribersReducer;
export { subscribersRoutine, subscribersSelectors };
