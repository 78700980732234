import PropTypes from 'prop-types';

export const timeTrackerPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string,
    work_order: PropTypes.number.isRequired,
});

export const downtimePropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_solved: PropTypes.bool.isRequired,
    work_order: PropTypes.number.isRequired,
});
