import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import { TABS } from 'core/timeplan/utils/calendar';

export const clientPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    hue: PropTypes.number.isRequired,
    orders: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const orderPropType = PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    date: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj])
        .isRequired,
    clientId: PropTypes.number.isRequired,
    articleGroups: PropTypes.arrayOf(PropTypes.number).isRequired,
    comment: PropTypes.string.isRequired,
});

export const articleGroupPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    orderId: PropTypes.number.isRequired,
    partProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
    description: PropTypes.string.isRequired,
    is_internal: PropTypes.bool.isRequired,
});

export const partProductPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    articleGroupId: PropTypes.number.isRequired,
    workPlan: PropTypes.array.isRequired, // TODO: Better prop type
    workOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const treeItemPropType = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.string,
    is_internal: PropTypes.bool,
    expanded: PropTypes.bool,
    children: PropTypes.array, // Nested array of treeItems
    numberOfChildren: PropTypes.number,
    selected: PropTypes.bool,
    selectedSome: PropTypes.bool,
    warning: PropTypes.string,
    warningSome: PropTypes.bool,
});

/**
 * Helper shape definition so that it can be reused for all types of blockers.
 */
const blockerShape = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    period_start: PropTypes.string.isRequired, // ISO-8601 notation (yyyy-mm-ddThh:mm:ssZ)
    period_end: PropTypes.string.isRequired, // ISO-8601 notation (yyyy-mm-ddThh:mm:ssZ)
    start_minute: PropTypes.number.isRequired,
    weekdays: PropTypes.number.isRequired,
};

/**
 * PipeBlocker model in the back-end.
 */
export const blockerPropType = PropTypes.shape(blockerShape);

/**
 * PartBlocker model in the back-end.
 */
export const partBlockerPropType = PropTypes.shape({
    ...blockerShape,
    operators: PropTypes.arrayOf(PropTypes.number).isRequired,
});

/**
 * One Blocker "instance" or bubble.
 */
export const singleBlockerPropType = PropTypes.shape({
    ...blockerShape,
    start: momentPropTypes.momentObj.isRequired,
    end: momentPropTypes.momentObj.isRequired,
    selected: PropTypes.bool.isRequired,
});

export const singlePartBlockerPropType = PropTypes.shape({
    ...blockerShape,
    start: momentPropTypes.momentObj.isRequired,
    end: momentPropTypes.momentObj.isRequired,
    selected: PropTypes.bool.isRequired,
    operators: PropTypes.arrayOf(PropTypes.number).isRequired,
});

/**
 * Tree view tab name.
 */
export const tabPropType = PropTypes.oneOf([
    TABS.PLANNED,
    TABS.UNPLANNED,
    TABS.BLOCKERS,
]);
