import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clsx } from 'clsx';

import { pauseTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';
import { gettext } from 'core/utils/text';

import { FaPauseCircle } from 'react-icons/fa';
import styles from './Styles.scss';

const PauseButton = ({ latestTrackerId, isRunning, pauseTimeTracker }) => (
    <button
        className={clsx(styles.pauseButton, {
            [styles.isRunning]: isRunning,
        })}
        onClick={(e) => {
            e.stopPropagation();
            pauseTimeTracker({ id: latestTrackerId });
        }}
    >
        <FaPauseCircle
            className={styles.pause}
            aria-label={gettext('Pause time tracker')}
        />
    </button>
);

PauseButton.propTypes = {
    latestTrackerId: PropTypes.number,
    isRunning: PropTypes.bool,
    pauseTimeTracker: PropTypes.func.isRequired,
};

PauseButton.defaultProps = {
    latestTrackerId: null,
    isRunning: false,
};

export default connect(null, { pauseTimeTracker: pauseTimeTrackerRoutine })(
    PauseButton,
);
