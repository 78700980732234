import moment from 'moment';
import uuidv4 from 'uuid/v4';

import { BODY_TYPE, WORK_ORDER_STATUS } from 'parts/workplan/constants';
import translations from '../../core/utils/translations';

/**
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').PartProduct}
 *  PartProduct
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').Order}
 *  Order
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').Client}
 *  Client
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').ArticleGroup}
 *  ArticleGroup
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').ActionGroup}
 *  ActionGroup
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').WorkPlanAction}
 *  WorkPlanAction
 * @typedef
 *  {import('parts/timeplan/reducers/timeplanReducers').ActionGroupAllInfo}
 *  ActionGroupAllInfo
 * @typedef
 *  {import('parts/timeplan/reducers/timeplanReducers').ActionGroupWorkOrder}
 *  ActionGroupWorkOrder
 */

/**
 * @typedef {import('parts/workplan/ducks/workOrders').WorkOrderNormalized}
 * WorkOrderNormalized
 */

/**
 * Calculate the id of an action group.
 *
 * @param {number|string} partProductId
 * @param {number|string} [operatorId]
 * @param {number|string} [workOrderId]
 *
 * @returns {string}
 */
export const actionGroupId = (partProductId, operatorId, workOrderId) =>
    `${partProductId}__${operatorId || 'UNASSIGNED'}__${
        workOrderId || 'UNPLANNED'
    }`;

/**
 * Convert a planned action group ID to an unplanned one.
 *
 * @param {string} id
 *
 * @returns {string}
 */
export const convertPlannedActionGroupIdToUnplanned = (id) => {
    const [productId, operatorId] = id.split('__');
    return actionGroupId(productId, operatorId);
};

/**
 * Get the action group ID for the given work order.
 * @param {{part_product: number, worker: number, id: number}} workOrder
 * @returns {string}
 */
export const actionGroupIdFromWorkOrder = (workOrder) =>
    actionGroupId(workOrder.part_product, workOrder.worker, workOrder.id);

/**
 * Construct a path for the given action in the work plan. This is useful so
 * that we can send this path to the back-end and the back-end would know
 * exactly which action it needs to change in the work plan.
 */
export const actionWorkPlanPath = (action, componentPart, component, product) =>
    `${product.work_plan_json.indexOf(component)}__${component.parts.indexOf(
        componentPart,
    )}__${
        componentPart
            ? // Body actions don't have a `componentPart` but we still need the
              // index in order to make a difference between body cutting and
              // expansion
              componentPart.actions.indexOf(action)
            : action.index
    }`;

/**
 * Generate an ID for an unassigned action based on its id and the part
 * product's id.
 *
 * @param {number} partProductId
 * @param {number} actionId
 * @param {number} index Index of the action in the work plan, important to
 *      make unique action IDs
 *
 * @returns {string}
 */
export const unassignedActionId = (partProductId, actionId, index) =>
    `${partProductId}__${actionId}__UNASSIGNED__${index}`;

/**
 * Parse the work order ID from the given action group ID. If there is no work
 * order ID in the action group ID, then return null.
 * @param {string} id Action group ID
 * @returns {number | null}
 */
export const getWorkOrderIdFromActionGroupId = (id) => {
    const [, , workOrderId] = id.split('__');
    // parseInt() can convert part of the UUID that used for not planed yet actions to a number
    return workOrderId === 'UNPLANNED' ||
        workOrderId === 'UNASSIGNED' ||
        !Number(workOrderId)
        ? null
        : parseInt(workOrderId, 10);
};

/**
 * Format the given work order + some extra data to an ActionGroup.
 *
 * Can also be called with the action group as the parameter like so:
 * `formatActionGroup(actionGroup)` in order to get the moment-converting of
 * the start and end time. This should be done when fetching `actionGroups`
 * from the Redux store.
 *
 * @param {{
 *  workOrder?: WorkOrderNormalized | ActionGroupWorkOrder
 *  workerId: number
 *  actions: WorkPlanAction[]
 *  client?: Client
 *  order?: Order
 *  articleGroup?: ArticleGroup
 *  partProduct?: PartProduct
 *  duration?: number
 *  unassigned?: boolean
 * }} params
 * @returns {ActionGroupAllInfo}
 */
export const formatActionGroup = ({
    workOrder,
    actions,
    workerId,
    duration,

    client,
    order,
    articleGroup,
    partProduct,
    // The `unassigned` value is used to keep track of action groups which were
    // previously unassigned but then were planned. In that case, we need to
    // pass this info to the back-end so that the back-end can assign the
    // actions in the work plan to that worker as well
    unassigned,
}) => ({
    id: actionGroupId(partProduct.id, workerId, workOrder?.id ?? uuidv4()),
    workOrder: workOrder
        ? {
              ...workOrder,
              id: workOrder.id ?? uuidv4(),
              workOrderActions: workOrder.workOrderActions ?? actions,
              startTime: workOrder.startTime ?? moment(workOrder.start_time),
              endTime: workOrder.endTime ?? moment(workOrder.end_time),
          }
        : null,
    actions,
    duration: duration ?? workOrder.duration,

    workerId,
    partProductId: partProduct.id,

    client,
    order,
    articleGroup,
    partProduct,
    unassigned,
});

/**
 * Get a new action object representing body cutting.
 *
 * @param {number} operatorId
 *
 * @returns {WorkPlanAction}
 */
export const cuttingAction = (operatorId) => ({
    id: DJ_CONST.BODY_CUTTING_ACTION_ID,
    worker_id: operatorId,
    action_name: translations.body_cutting,
    index: 0,
});

/**
 * Get a new action object representing body expansion.
 *
 * @param {number} operatorId
 *
 * @returns {WorkPlanAction}
 */
export const expansionAction = (operatorId) => ({
    id: DJ_CONST.BODY_EXPANSION_ACTION_ID,
    worker_id: operatorId,
    action_name: translations.body_expansion,
    index: 1,
});

/**
 * Get the body component from the given work plan.
 *
 * @param {WorkPlan} workPlan
 *
 * @returns {WorkPlanComponent}
 */
export const getBodyComponent = (workPlan) =>
    workPlan.find((component) => component.type === BODY_TYPE);

/**
 * Check if the given component needs cutting.
 *
 * @param {WorkPlanComponent} component
 *
 * @return {boolean}
 */
export const needsCutting = (component) =>
    component.type === BODY_TYPE && component.needs_cutting;

/**
 * Check if the given component needs expansion.
 *
 * @param {WorkPlanComponent} component
 *
 * @return {boolean}
 */
export const needsExpansion = (component) =>
    component.type === BODY_TYPE &&
    component.expansion_type &&
    component.expansion_count;

/**
 * Convert the part product object from API calls to the format mainly handled
 * in JavaScript and saved in the Redux store.
 *
 * @param {Object} partProduct
 *
 * @returns {PartProduct}
 */
export const formatPartProductFromApi = (partProduct) => ({
    id: partProduct.id,
    articleGroupId: partProduct.order_row,
    comment: partProduct.comment,
    workPlan: partProduct.work_plan_json,
    workOrders: partProduct.work_orders.map((workOrder) => workOrder.id),
    timePerWorker: partProduct.time_per_worker,
    unassignedActionDurations: partProduct.unassigned_action_durations,
});

/**
 * Loop through all actions for the given part product including body actions
 * (cutting and expansion).
 *
 * @param {PartProduct} partProduct
 * @param {Function} callback
 * @param {Function} [filter] - filter actions
 */
export const forEachAction = (partProduct, callback, filter = () => true) => {
    partProduct.workPlan.forEach((component) => {
        component.parts.forEach((componentPart) => {
            componentPart.actions.filter(filter).forEach((action) => {
                callback(action, componentPart, component);
            });
        });
    });

    // In addition to normal actions, we also want to handle body actions like
    // cutting and expansion
    const bodyComponent = getBodyComponent(partProduct.workPlan);
    if (
        bodyComponent &&
        'cutting_operator' in bodyComponent &&
        'expansion_operator' in bodyComponent
    ) {
        const unassignedActions = Object.keys(
            partProduct.unassignedActionDurations,
        );
        if (
            needsCutting(bodyComponent) ||
            unassignedActions.includes(`${DJ_CONST.BODY_CUTTING_ACTION_ID}`)
        ) {
            const action = cuttingAction(bodyComponent.cutting_operator);
            if (filter(action)) {
                callback(action, null, bodyComponent);
            }
        }

        if (
            needsExpansion(bodyComponent) ||
            unassignedActions.includes(`${DJ_CONST.BODY_EXPANSION_ACTION_ID}`)
        ) {
            const action = expansionAction(bodyComponent.expansion_operator);
            if (filter(action)) {
                callback(action, null, bodyComponent);
            }
        }
    }
};

/**
 * Convert an API response containing part products' data to a format that is
 * held in the Redux store. In addition, removes out-of-date action groups.
 *
 * An initial `actionGroups` state can be given, causing this function to update
 * the given state. Since `actionGroups` contain `action`s, new `action`s will
 * be added to the existing `actionGroups`.
 *
 * This function is quite complicated, the main reason for this is that we want
 * to avoid adding duplicate actions to action groups.
 *
 * @param {Array<Object>} partProducts
 * @param {import('parts/timeplan/reducers/timeplanReducers').ActionGroupState} [initialActionGroups]
 * @param {Function} [filter] - filter function for actions that are looped over
 *
 * @returns {import('parts/timeplan/reducers/timeplanReducers').ActionGroupState}
 */
export const mapPartProductsAPIResponseToActionGroupsStoreFormat = (
    partProducts,
    initialActionGroups = {},
    filter = () => true,
) => {
    const actionGroups = { ...initialActionGroups };

    // region === Remove old out of date action groups

    const removeActionGroup = (workOrder, product, action) => {
        const id = actionGroupId(product.id, action.worker_id, workOrder?.id);
        const unplannedId = convertPlannedActionGroupIdToUnplanned(id);
        const unassignedId = unassignedActionId(
            product.id,
            action.id,
            action.index,
        );

        delete actionGroups[id];
        delete actionGroups[unplannedId];
        delete actionGroups[unassignedId];
    };

    // endregion

    // region === Add the new action groups

    /**
     * Keep track for each work order which action IDs we have already handled.
     * This is useful to get rid of duplicate actions in action groups.
     */
    const workOrderHandledActionIds = {};
    /**
     * Keep track for each part product which actions we have already handled.
     */
    const partProductHandledActions = {};
    const addActionToActionGroups = (
        workOrder,
        product,
        action,
        componentPart,
        component,
    ) => {
        if (workOrder && !workOrderHandledActionIds[workOrder.id]) {
            workOrderHandledActionIds[workOrder.id] = [];
        }
        if (!partProductHandledActions[product.id]) {
            partProductHandledActions[product.id] = [];
        }

        const workPlanPath = actionWorkPlanPath(
            action,
            componentPart,
            component,
            product,
        );

        if (partProductHandledActions[product.id].includes(workPlanPath)) {
            return;
        }

        const workOrderActions = workOrder
            ? (workOrder.work_order_actions ?? []).map(
                  (workOrderAction) => workOrderAction.action.id,
              )
            : [];
        if (workOrder) {
            workOrderHandledActionIds[workOrder.id].forEach((actionId) => {
                const index = workOrderActions.indexOf(actionId);
                workOrderActions.splice(index, 1);
            });
        }

        const isPlanned = Boolean(workOrder);
        const isActionIncludedInWorkOrder = workOrderActions.includes(
            action.id,
        );
        const isWorkOrderForCorrectWorker =
            (workOrder?.worker ?? null) === action.worker_id;
        const isRelevantAction =
            isWorkOrderForCorrectWorker && isActionIncludedInWorkOrder;

        if (isPlanned && !isRelevantAction) {
            return;
        }

        const id = action.worker_id
            ? actionGroupId(product.id, action.worker_id, workOrder?.id)
            : unassignedActionId(product.id, action.id, action.index);

        if (workOrder && workOrder.status === WORK_ORDER_STATUS.DONE) {
            workOrderHandledActionIds[workOrder.id].push(action.id);
            partProductHandledActions[product.id].push(workPlanPath);
            return;
        }

        if (!(id in actionGroups)) {
            actionGroups[id] = {
                id,
                workOrder,
                actions: [],
                duration: 0, // Updated to correct duration later in this fn

                workerId: action.worker_id,
                partProductId: product.id,
            };
        }

        const actionGroup = actionGroups[id];

        // Only keep track of individual actions' durations when they aren't grouped
        // TODO: Why tho?
        actionGroup.actions.push({
            ...action,
            planPart: componentPart,
            duration: action.worker_id
                ? null
                : product.unassigned_action_durations[action.id].result,
            workPlanPath,
        });

        const actionDuration = actionGroup.actions.reduce(
            (prev, a) => (a.duration ? prev + a.duration : prev),
            0,
        );

        // action group should always have a duration, otherwise time plan dnd will create zero length bubbles
        // later on this duration will be multiplied by the worker time factor
        actionGroup.duration = action.worker_id
            ? product.time_per_worker[action.worker_id].result
            : actionDuration;

        if (workOrder) {
            workOrderHandledActionIds[workOrder.id].push(action.id);
        }
        partProductHandledActions[product.id].push(workPlanPath);
    };

    // endregion

    // region === Helper function to iterate over all identifiers

    /**
     * Iterate over all actions and work orders. Basically, call `callback` for
     * each combination of `action` in the work plan and each `WorkOrder` for
     * that each `PartProduct`.
     *
     * @param {Function} callback
     */
    const iterateOverWorkPlan = (callback) => {
        partProducts.forEach((product) => {
            forEachAction(
                formatPartProductFromApi(product),
                (action, componentPart, component) => {
                    const workOrders = [...product.work_orders];
                    workOrders.push(null);

                    workOrders.forEach((workOrder) => {
                        callback(
                            workOrder,
                            product,
                            action,
                            componentPart,
                            component,
                        );
                    });
                },
                (action) => filter(action, product),
            );
        });
    };

    // endregion

    iterateOverWorkPlan(removeActionGroup);
    iterateOverWorkPlan(addActionToActionGroups);

    return actionGroups;
};

/**
 * Simple helper function for flatten out a tree's nodes and children.
 */
export function* flattenTree(children, getMoreChildren) {
    if (children) {
        for (const child of children) {
            yield child;
            yield* flattenTree(getMoreChildren(child), getMoreChildren);
        }
    }
}
