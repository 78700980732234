import { combineReducers } from 'redux';

import api from 'core/utils/api';
import { ACTION_TYPES } from 'parts/workplan/constants';

// region =========== Type definitions ===========

/**
 * @typedef {Object} WorkPlanActionSerialized
 * @property {Number} id
 * @property {String} name
 * @property {Number} part_type_id
 * @property {Array<String>} used_variables
 * @property {Number} order
 * @property {String} formula
 * @property {Number} work_type
 */

/**
 * @typedef {Object} WorkPlanActionsState
 * @property {Object.<String, WorkPlanActionSerialized>} actions
 * @property {Boolean} isLoading
 */

// endregion

const SET_LOADING = 'actions/SET_LOADING';
const SET_ACTIONS = 'actions/SET_ACTIONS';

/**
 * @param {WorkPlanActionsState} state
 * @param {Object} action
 */
const actionsReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_ACTIONS:
            return action.actions;

        default:
            return state;
    }
};

/**
 * @param {Boolean} state
 * @param {Object} action
 * @returns {Boolean}
 */
const isLoadingReducer = (state = false, action) => {
    switch (action.type) {
        case SET_LOADING:
            return action.loading;

        default:
            return state;
    }
};

export default combineReducers({
    actions: actionsReducer,
    isLoading: isLoadingReducer,
});

// region =========== Actions ===========

const setLoading = (loading) => ({ type: SET_LOADING, loading });
const setActions = (actions) => ({ type: SET_ACTIONS, actions });

export const fetchActions = () => async (dispatch) => {
    dispatch(setLoading(true));

    let response = null;
    try {
        response = await api.partActionsList.fetch();
    } catch (err) {
        dispatch(setLoading(false));
        console.error(err);
        return;
    }

    const actions = {};
    response.forEach((action) => {
        actions[action.id] = action;
    });

    dispatch(setActions(actions));
    dispatch(setLoading(false));
};

// endregion

export const actionsSelectors = {
    byId: (state, id) => state.actions.actions[id],
    bodyCuttingAction: (state) =>
        Object.values(state.actions.actions).find(
            (action) => action.action_type === ACTION_TYPES.BODY_CUTTING,
        ),
    bodyExpansionAction: (state) =>
        Object.values(state.actions.actions).find(
            (action) => action.action_type === ACTION_TYPES.BODY_EXPANSION,
        ),
};
