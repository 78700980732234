import createEntityDuck from 'core/ducks/generic/entity';
import { operationsPageRoutine } from 'parts/operations/sagas/operationsViewSaga';

export const STATE_KEY = 'actions';

const [baseReducer, actionsRoutine, selectors] = createEntityDuck(STATE_KEY);

const actionsReducer = (currentState = undefined, action) => {
    // First evaluate the base reducer
    const state = baseReducer(currentState, action);

    switch (action.type) {
        case operationsPageRoutine.SUCCESS:
            return baseReducer(
                state,
                actionsRoutine.success({
                    byId: action.payload.actions,
                }),
            );

        default:
            return state;
    }
};

export default actionsReducer;

export { actionsRoutine, selectors };
