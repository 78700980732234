/* eslint-disable */

import { Resource } from 'tg-resources';

import api from 'core/utils/api';
import {
    CLEAR_PIPES,
    RECEIVE_OPERATORS,
    RECEIVE_PIPES,
    RECEIVE_STATISTICS,
    SET_IS_LOADING,
    TOGGLE_IS_LOADING,
    TRIGGER_PIPE_LIST_ERROR,
    UPDATE_CURSOR,
} from './types';

export const receivePipes = (pipes) => ({
    type: RECEIVE_PIPES,
    pipes,
});

export const receivePipesStatistics = (statistics) => ({
    type: RECEIVE_STATISTICS,
    statistics,
});

export const clearPipes = () => ({ type: CLEAR_PIPES });

export const receiveCursor = (previous, next) => ({
    type: UPDATE_CURSOR,
    previous,
    next,
});

export const receiveOperators = (operators) => ({
    type: RECEIVE_OPERATORS,
    operators,
});

export const toggleIsLoading = () => ({
    type: TOGGLE_IS_LOADING,
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const triggerError = (statusCode = 500) => ({
    type: TRIGGER_PIPE_LIST_ERROR,
    statusCode,
});

// Util function
export const getFetchQuery = (listFilters) => {
    const {
        startDate,
        endDate,
        queryString,
        statusQuery,
        querySet,
    } = listFilters;
    const fetchQuery = {};

    if (startDate) {
        fetchQuery.start_date = startDate;
    }
    if (endDate) {
        fetchQuery.end_date = endDate;
    }
    if (queryString) {
        fetchQuery.query_string = queryString;
    }
    if (statusQuery) {
        fetchQuery.status = statusQuery;
    }
    if (Object.keys(querySet).length) {
        fetchQuery.filter_cluster = JSON.stringify(
            Object.entries(querySet).reduce((acc, [filterKey, filterValue]) => {
                acc[filterKey] = filterValue.filter(
                    (filter) => !filter.disabled,
                );
                return acc;
            }, {}),
        );
    }

    return fetchQuery;
};

export const fetchPipes = (force = false, statistics = false) => (
    dispatch,
    getState,
) => {
    const state = getState();

    if (!state.isOnline && !force) {
        return null;
    }

    const onError = (err) => {
        dispatch(triggerError(err.statusCode));
    };
    const options = {
        headers: () => ({
            accept: 'application/json',
        }),
    };

    const fetchQuery = getFetchQuery(state.listFilters);
    if (statistics) {
        fetchQuery.statistics = true;
    }
    const { previous, next } = state.pipeList.cursor;
    const isFirstLoad = !previous && !next;

    if (previous && !next && !force) {
        return null; // we're out of things to load
    }

    const getPipes = new Resource(next || DJ_CONST.pipe_list_url, options);

    if (isFirstLoad) {
        dispatch(toggleIsLoading());
    }

    // They want to prevent Enter key from re-opening the <Select> dropdown, but we can't blur the element with `autoBlur`
    // prop on the <Select> component, because we'd be losing the option to trigger this action with Enter key. So, we're
    // doing it here, after the action is triggered.
    if (document.activeElement) {
        document.activeElement.blur();
    }

    return getPipes
        .fetch(null, fetchQuery)
        .then((response) => {
            if (isFirstLoad) {
                dispatch(toggleIsLoading());
                dispatch(clearPipes());
            }
            dispatch(receiveCursor(response.previous, response.next));
            dispatch(receivePipes(response.results.pipes));
            dispatch(receivePipesStatistics(response.results.statistics));
        })
        .catch(onError);
};

export const fetchOperators = () => (dispatch) => {
    const onError = (err) => {
        dispatch(triggerError(err.statusCode));
    };

    return api.operatorsList
        .fetch(null)
        .then((response) => {
            dispatch(receiveOperators(response));
        })
        .catch(onError);
};
