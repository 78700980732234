import { gettext } from 'core/utils/text';

// Work types related to Action model
export const WORK_TYPE_EL = 1;
export const WORK_TYPE_MT = 2;
export const WORK_TYPE_KEEV = 3;

export const COMPONENT_TYPES = ['body', 'end'];
export const BODY_TYPE = 1;
export const END_TYPE = 2;
export const COMPONENT_TYPE_VALUES = [BODY_TYPE, END_TYPE];
export const COMPONENT_TYPE_LABELS = {
    body: gettext('body'),
    end: gettext('end'),
};

/**
 * Keep in sync with action types in the Action model!
 */
export const ACTION_TYPES = {
    NORMAL_ACTION: 0,
    BODY_CUTTING: 1,
    BODY_EXPANSION: 2,
    EXTRA_TIME: 3,
};

/**
 * Keep in sync with the status in the WorkOrderAction model!
 */
export const WORK_ORDER_ACTION_STATUSES = {
    NOT_STARTED: 0,
    IN_PROGRESS: 1,
    DONE: 2,
};

/**
 * The statuses that a work order can be in. This is not saved from the
 * database but they should be calculated based on the timers related to a work
 * order.
 */
export const WORK_ORDER_STATUS = {
    /**
     * Work order has no timers.
     */
    NOT_STARTED: 0,
    /**
     * Work order has a timer that has no end time.
     */
    RUNNING: 1,
    /**
     * Work order has timers but they all have an end time.
     */
    PAUSED: 2,
    /**
     * Work order has been completed.
     */
    DONE: 3,
    /**
     * Work order has a downtime (paused because of some problem).
     */
    DOWNTIME: 4,
};
