import moment from 'moment';

/**
 * Get the blockers based on the given overtimes. from the start time to the end
 * time.
 *
 * For each day between the start and end time, keep track of the previous day's
 * blocker start time (regular work time end) and the current day's blocker end
 * time (regular work time start). Also keep track of all overtimes in the night
 * time between those times. Then, we can create blockers that span from the
 * last day's evening to the current day's morning.
 *
 * Also takes into account lunch and resting times. Since the lunch and resting
 * times are just durations and don't have a fixed time, we will just end days
 * earlier based on how long the lunch/resting times are. For example, if the
 * regular working time ends at 17:00 and the lunch and resting times are both
 * 30 minutes, the blocker would start at 16:00.
 *
 * @param {Array<Overtime>} overtimes
 * @param {Moment} startTime
 * @param {Moment} endTime
 *
 * @return {Array<Blocker>} - blockers sorted chronologically
 */
export const getWorkTimeBlockers = (overtimes, startTime, endTime) => {
    const blockers = [];

    const regularWorkTimeStart = moment
        .utc(DJ_CONST.REGULAR_WORK_TIME_START, 'h:mm')
        .local();
    const regularWorkTimeEnd = moment
        .utc(DJ_CONST.REGULAR_WORK_TIME_END, 'h:mm')
        .local();
    const lunchDuration = moment.duration(DJ_CONST.LUNCH_DURATION);
    const restingTimesDuration = moment.duration(
        DJ_CONST.RESTING_TIMES_DURATION,
    );

    // Moment-ify overtime start and end times so that time logic is easier
    const momentOvertimes = overtimes.map((overtime) => ({
        ...overtime,
        start_time: moment.utc(overtime.start_time).local(),
        end_time: moment.utc(overtime.end_time).local(),
    }));

    const currentDay = startTime.clone();
    let currentBlockerStart;
    let currentBlockerEnd;
    // Keep track of previous overtimes so that we can group weekend overtimes
    // and subtract them from the start of the gap that starts on Friday and
    // ends on Monday morning.
    let previousDaysOvertimes = [];
    /**
     * Use all gathered previous days' overtimes to create blockers. In addition
     * change the last blocker of the day's start time to the last overtime's
     * end time.
     */
    const addPreviousDaysBlockers = () => {
        previousDaysOvertimes.forEach((overtime) => {
            // For each overtime we can add a blocker that is from the "current"
            // blocker start time until the overtime's start time
            blockers.push({
                start: currentBlockerStart,
                end: overtime.start_time.clone(),
            });

            currentBlockerStart = overtime.end_time.clone();
        });
        previousDaysOvertimes = [];
    };

    while (currentDay.isBefore(endTime)) {
        const todayWorkTimeStart = currentDay
            .clone()
            .add(regularWorkTimeStart.hours(), 'hours')
            .add(regularWorkTimeStart.minutes(), 'minutes');

        const currentDayOvertimes = momentOvertimes.filter(
            (o) =>
                o.start_time.isAfter(
                    todayWorkTimeStart.clone().subtract(1, 'day'),
                ) && o.start_time.isBefore(todayWorkTimeStart),
        );
        previousDaysOvertimes.push(...currentDayOvertimes);

        if (currentDay.isoWeekday() > 5) {
            // No working times on the weekend, meaning no extra blockers. If
            // there is overtime on the weekend, it will be subtracted from to
            // the blocker which spans over the weekend.
            currentDay.add(1, 'days');
            continue; // eslint-disable-line no-continue
        }

        currentBlockerEnd = todayWorkTimeStart.clone();

        if (currentBlockerStart) {
            addPreviousDaysBlockers();

            // Add the current blocker (last of the day's) to blockers' list
            blockers.push({
                start: currentBlockerStart,
                end: currentBlockerEnd,
            });
        }

        // Today's blocker start time can be used tomorrow, since then we also
        // have the blocker end time (which is tomorrow morning).
        currentBlockerStart = currentDay
            .clone()
            .add(regularWorkTimeEnd.hours(), 'hours')
            .add(regularWorkTimeEnd.minutes(), 'minutes')
            .subtract(lunchDuration)
            .subtract(restingTimesDuration);

        currentDay.add(1, 'days');
    }

    return blockers.sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1));
};
