import { BODY_TYPE } from 'parts/workplan/constants';

/**
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').PartProduct}
 * PartProduct
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').WorkPlan}
 * WorkPlan
 * @typedef {import('parts/timeplan/reducers/timeplanReducers').ActionGroup}
 * ActionGroup
 */

/**
 * @typedef {import('parts/workplan/ducks/workOrders').WorkOrderWithActions}
 * WorkOrderWithActions
 */

export const TABS = {
    PLANNED: 'planned',
    UNPLANNED: 'unplanned',
    BLOCKERS: 'blockers',
};

/**
 * Returns human name as array of first part of the name and last one
 * @param {string} name Name to be splitted
 * @return {string[]} First part of name and the last one
 */
export const splitName = (name) => {
    const splitted = name.split(' ');
    if (splitted.length > 2) {
        const lastPart = splitted.pop();
        return [splitted.join(' '), lastPart];
    } else {
        return splitted;
    }
};

/**
 * Generic utility function to check if there are any items selected other than
 * this item.
 *
 * If this item is NOT selected, then we require at least 1 item in toral to be
 * selected. But if the current item IS selected, then we require at least 2
 * items to be selected.
 *
 * @param {boolean} isSelected
 * @param {number} selectedCount
 *
 * @return {boolean}
 */
export const hasOthersSelected = (isSelected, selectedCount) =>
    (!isSelected && selectedCount >= 1) || (isSelected && selectedCount >= 2);

/**
 * Format the label for an action group in the tree view. Since these labels are
 * generated on the go while looping over actions (not all at once), then the
 * accepted action is just the first action in the group. Later on, next actions
 * will be appended to the label.
 *
 * For example:
 *
 * ("Cutting", "Riho") -> "Riho - Cutting"
 * ("Welding") -> "Unassigned - Welding"
 * ("Welding", "Riho", [workOrder1, workOrder2], 0) -> "Riho - Cutting 1/2"
 * ("Welding", "Riho", [workOrder1], 0) -> "Riho - Cutting"
 *
 * @param {string} firstActionName - name of the first action in the group
 * @param {string|null} workerName - name of the worker whose action group it is
 * @param {string[]} [workOrdersForSamePerson] - work orders for the same
 *      worker (only useful/required when planned actions are formatted since
 *      unplanned actions don't have work orders)
 * @param {number} [workOrderIndex] - index for the current work order (only
 *      useful/required when planned actions are formatted since unplanned
 *      actions don't have work orders).
 *
 * @return {string}
 */
export const formatActionGroupTreeLabel = (
    firstActionName,
    workerName = null,
    workOrdersForSamePerson = [],
    workOrderIndex = 0,
) => {
    const name = workerName || gettext('Unassigned');
    const identifierLabelPostfix =
        workOrdersForSamePerson.length > 1
            ? ' (' +
              (workOrderIndex + 1) +
              '/' +
              workOrdersForSamePerson.length +
              ')'
            : '';

    return `${name} - ${firstActionName}${identifierLabelPostfix}`;
};

/**
 * Add an action to the given action group tree item label. Since the labels are
 * created while looping over actions, we can't create the whole label in one go
 * but need to append action names to it.
 *
 * This just appends the given action's name to the label using a comma.
 *
 * For example:
 *
 * ("Riho - Welding", "Cutting") -> "Riho - Welding, Cutting"
 *
 * @param {string} label
 * @param {string} actionName
 *
 * @return {string}
 */
export const addActionToActionGroupTreeItem = (label, actionName) =>
    `${label}, ${actionName}`;

/**
 * Get the work plan that is included in a bubble. This is used to show extra
 * information about the included actions in the bubble tooltip.
 *
 * 1. Use `filter` to only keep components and parts that have any actions
 *    inside of them.
 *
 * @param {PartProduct} partProduct
 * @param {ActionGroup} actionGroup
 * @param {number} bodyCuttingActionId
 * @param {number} bodyExpansionActionId
 *
 * @return {WorkPlan}
 */
export const getWorkPlanIncludedInActionGroup = (
    partProduct,
    actionGroup,
    bodyCuttingActionId,
    bodyExpansionActionId,
) => {
    const actionIds = actionGroup.actions.map((action) => action.id);
    /**
     * Check if the given action ID is included in this action group.
     *
     * If it is included, then will REMOVE it from the `actionIds`. This is
     * because we only want to match each action ONCE in the bubble. If the
     * same action is assigned to the same person twice BUT is only in ONE
     * bubble, then the `actionIds` would include the ID ONCE. If there are
     * multiple actions with the same ID in one action group, then `actionIds`
     * will include that ID multiple times.
     *
     * @param {number} actionId
     * @param {number} [workerId] If worker ID is given, then will also check
     *  that it matches the action group's worker ID
     *
     * @returns {boolean}
     */
    const isIncludedInBubble = (actionId, workerId = undefined) => {
        let included = actionIds.includes(actionId);
        if (workerId) {
            included = included && workerId === actionGroup.workerId;
        }

        if (included) {
            actionIds.splice(actionIds.indexOf(actionId), 1);
        }

        return included;
    };

    const includedWorkPlan = partProduct.workPlan
        // Only return body actions if the action group contains the body
        // actions
        // If we don't do this here, then body actions will be shown on EVERY
        // bubble if the body actions are required for that par product
        .map((component) => {
            if (component.type !== BODY_TYPE) {
                return component;
            }
            const cuttingInActionGroup = isIncludedInBubble(
                bodyCuttingActionId,
            );
            const expansionInActionGroup = isIncludedInBubble(
                bodyExpansionActionId,
            );

            return {
                ...component,
                needs_cutting: component.needs_cutting && cuttingInActionGroup,
                expansion_type:
                    component.expansion_type && expansionInActionGroup
                        ? component.expansion_type
                        : null,
                expansion_count:
                    component.expansion_count && expansionInActionGroup
                        ? component.expansion_count
                        : 0,
            };
        })
        .map((component) => ({
            ...component,
            parts: component.parts
                .map((part) => ({
                    ...part,
                    actions: part.actions.filter((a) =>
                        isIncludedInBubble(a.id, a.worker_id),
                    ),
                }))
                .filter((part) => !!part.actions.length) /* 1 */,
        }));

    return includedWorkPlan;
};
