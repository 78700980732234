import PropTypes from 'prop-types';
import React from 'react';

import { gettext } from 'core/utils/text';

const ErrorState = ({ code, message, errors }) => {
    if (message) {
        return <div>{message}</div>;
    }
    if (errors && Object.keys(errors).length) {
        const fieldErrors = Object.entries(errors).map(([key, e]) => (
            <h4 className="text-danger" key={key}>
                {e}
            </h4>
        ));
        return <div>{fieldErrors}</div>;
    }

    if (code === 403) {
        return (
            <div>
                {gettext(
                    "You don't have permission to do that. Please check you haven't logged out in another tab.",
                )}
            </div>
        );
    }
    return (
        <div>
            {gettext(
                'There might be a slight problem somewhere deep in the server... :/',
            )}
        </div>
    );
};

ErrorState.propTypes = {
    code: PropTypes.number,
    message: PropTypes.string,
    errors: PropTypes.object,
};

export default ErrorState;
