import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import styles from './CollapsibleItem.scss';

const CollapsibleItem = ({
    header,
    children,
    collapsedInitially = false,
    getHeaderClassName = undefined,
    className = undefined,
    targetAnchorId = undefined,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsedInitially);

    const toggleCollapsed = () => {
        setIsCollapsed((collapsed) => !collapsed);
    };

    return (
        <div className={clsx(styles.listItem, className)}>
            <div
                id={targetAnchorId}
                className={clsx(
                    styles.listItemHeader,
                    getHeaderClassName && getHeaderClassName(isCollapsed),
                )}
                onClick={toggleCollapsed}
            >
                {header(isCollapsed)}
            </div>
            {!isCollapsed && children}
        </div>
    );
};

CollapsibleItem.propTypes = {
    header: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    collapsedInitially: PropTypes.bool,
    getHeaderClassName: PropTypes.func,
    className: PropTypes.string,
    targetAnchorId: PropTypes.string,
};

export default CollapsibleItem;
