import { Resource } from 'tg-resources';
import { schema } from 'normalizr';

import api from 'core/utils/api';
import {
    actionGroupId,
    convertPlannedActionGroupIdToUnplanned,
    unassignedActionId,
} from 'parts/utils/parts';
import { setStateHash } from 'core/timeplan/ducks/stateHash';
import { setError } from 'pipes/timeplan/actions/timeplanActions';
import { getSelectedActionGroups } from 'parts/timeplan/reducers/timeplanSelectors';
import { workOrdersSelectors } from 'parts/workplan/ducks/workOrders';
import { PartOrderStatus } from '../../../codegen/text_choices';

/**
 * @typedef
 * {import('parts/timeplan/reducers/timeplanReducers').ActionGroupAllInfo}
 * ActionGroupAllInfo
 */

export const RECEIVE_ORDERS = 'timeplan/parts/RECEIVE_ORDERS';

export const SET_LOADING = 'timeplan/parts/SET_LOADING';

export const SELECT_DATE = 'timeplan/parts/SELECT_DATE';

export const SET_DROPPING = 'timeplan/parts/SET_DROPPING';

export const RECEIVE_PRODUCTS = 'timeplan/parts/RECEIVE_PRODUCTS';
export const RECEIVE_UNPLANNED_PRODUCTS =
    'timeplan/parts/RECEIVE_UNPLANNED_PRODUCTS';

// region =========== Tree ===========

export const SELECT_TAB = 'timeplan/parts/SELECT_TAB';
export const TOGGLE_TREE_ITEM = 'timeplan/parts/TOGGLE_TREE_ITEM';
export const SELECT_LEAVES = 'timeplan/parts/SELECT_LEAVES';
export const TOGGLE_COLLAPSED = 'timeplan/parts/TOGGLE_COLLAPSED';
export const CLEAR_SELECTED_LEAVES = 'timeplan/parts/CLEAR_SELECTED_LEAVES';
export const UNPLAN_LEAVES = 'timeplan/parts/UNPLAN_LEAVES';

// endregion

// region =========== Tree actions ===========

/**
 * Select one or multiple leaves in the tree. Will select all leaf children of
 * the node.
 * @param {TreeItem} item
 * @returns {Array<TreeItemId>}
 */
export const collectLeafIds = (item) => {
    const ids = [];

    const addIds = (node) => {
        if (!node.children) {
            ids.push(node.id);
        } else if (node.children) {
            node.children.forEach(addIds);
        }
    };

    addIds(item);

    return ids;
};

/**
 * Toggle the collapsed/expanded state of a tree item.
 * @param {TreeItem} item
 * @returns {{type: string, item: TreeItem}}
 */
export const toggleTreeItem = (item) => ({ type: TOGGLE_TREE_ITEM, item });

/**
 * Change the active tab in the tree view.
 * @param {TreeViewTab} tabName
 * @returns {{type: string, tabName: TreeViewTab}}
 */
export const selectTab = (tabName) => ({ type: SELECT_TAB, tabName });

/**
 * Select the leaves with the given ids.
 * @param {Array<TreeItemId>} ids
 * @param {Boolean} [ctrl=false]
 * @returns {{type: string, ids: Array<TreeItemId>, ctrl: Boolean}}
 */
export const selectLeaves = (ids, ctrl = false) => ({
    type: SELECT_LEAVES,
    ids,
    ctrl,
});

/**
 * Toggle the collapsed state of the tree view. This either hides or shows the
 * sidebar.
 * @returns {{type: string}}
 */
export const toggleCollapsed = () => ({ type: TOGGLE_COLLAPSED });

/**
 * Clear all selected leaves.
 * @returns {{type: string}}
 */
export const clearSelectedLeaves = () => ({ type: CLEAR_SELECTED_LEAVES });

// endregion

// region =========== Action creators ===========

const setLoading = (loading) => ({ type: SET_LOADING, loading });

export const selectDate = (date) => ({ type: SELECT_DATE, date });

export const receiveOrders = (results) => ({ type: RECEIVE_ORDERS, results });
export const receiveProducts = (products) => ({
    type: RECEIVE_PRODUCTS,
    products,
});
export const receiveUnplannedProducts = (unplannedIds, products) => ({
    type: RECEIVE_UNPLANNED_PRODUCTS,
    unplannedIds,
    products,
});
export const setDropping = (ids) => ({ type: SET_DROPPING, ids });
export const unplanLeaves = (ids) => ({ type: UNPLAN_LEAVES, ids });

// endregion

// region =========== Async action creators ===========

export const partProductsSchema = new schema.Entity('partProducts', {
    work_orders: [
        new schema.Entity('workOrders', {
            work_order_actions: [
                new schema.Entity('workOrderActions', {
                    action: new schema.Entity('actions'),
                }),
            ],
        }),
    ],
});

export const fetchPartOrders = () => async (dispatch) => {
    const options = {
        headers: () => ({
            accept: 'application/json',
        }),
    };

    dispatch(setLoading(true));
    const getOrders = new Resource(DJ_CONST.order_list_url, options);
    let response = null;
    try {
        // we don't need all orders in timeplan
        response = await getOrders.fetch(null, {
            orders: false,
            has_workplan: true,
            exclude_deactivated_products: true,
            // Filter out already fully completed orders
            filter_cluster: JSON.stringify({
                status: [
                    {
                        operator: 'not_equals',
                        value: PartOrderStatus.COMPLETED,
                    },
                ],
            }),
        });
    } catch (err) {
        console.error(err);
        dispatch(setLoading(false));
        return;
    }

    dispatch(receiveOrders(response.results));
};

/**
 * Make a request to plan the given bubbles.
 *
 * @param {Object.<String, ActionGroupAllInfo[]>} selectedBubbles - grouped
 *      by worker
 *
 * @returns {Function}
 */
export const requestDropItems = (selectedBubbles) => async (
    dispatch,
    getState,
) => {
    // Since the input bubbles are grouped by workers, we need to flatten them
    // to a normal list in the format that the API expects.
    const changeSet = Object.values(selectedBubbles)
        .map((workerActionGroups) =>
            workerActionGroups.map((actionGroup) => ({
                id: actionGroup.partProduct.id,
                worker_id: actionGroup.workerId,
                work_order_id:
                    typeof actionGroup.workOrder.id === 'number'
                        ? actionGroup.workOrder.id
                        : null,
                start_time: actionGroup.workOrder.startTime,
                end_time: actionGroup.workOrder.endTime,
                duration: Math.round(actionGroup.duration),
                action_ids: actionGroup.actions.map((a) => a.id),
                was_unassigned: Boolean(actionGroup.unassigned),
                work_plan_action_paths: actionGroup.actions.map(
                    (a) => a.workPlanPath,
                ),
            })),
        )
        .reduce(
            (flattenedBubbles, current) => [...flattenedBubbles, ...current],
            [],
        );

    const actionGroupsToRemove = Object.values(selectedBubbles)
        .reduce((acc, actionGroups) => [...acc, ...actionGroups], [])
        .map((actionGroup) => {
            if (!actionGroup.unassigned) {
                return [
                    actionGroupId(
                        actionGroup.partProduct.id,
                        actionGroup.workerId,
                    ),
                ];
            }
            return actionGroup.actions.map((action) =>
                unassignedActionId(
                    actionGroup.partProduct.id,
                    action.id,
                    action.index,
                ),
            );
        })
        .reduce((acc, actionGroupIds) => [...acc, ...actionGroupIds], []);

    dispatch(setDropping([]));
    // nothing to do
    if (!changeSet.length) {
        return;
    }

    dispatch(setLoading(true));

    let response;
    try {
        // return affected products
        response = await api.partProductPlan.post(null, changeSet, {
            state_hash: getState().stateHash,
        });
    } catch (err) {
        dispatch(
            setError(
                err.statusCode,
                err.isValidationError ? err.firstError(true) : null,
            ),
        );
        dispatch(setLoading(false));
        return;
    }

    if (response) {
        dispatch(setStateHash(response.state_hash));
        // we need to clear action groups also :)
        dispatch(receiveUnplannedProducts(actionGroupsToRemove, response.data));
    }

    dispatch(setLoading(false));
};

export const requestUnplanActionGroups = () => async (dispatch, getState) => {
    const selectedActionGroups = getSelectedActionGroups(getState());

    const workOrderIds = selectedActionGroups.map((item) => item.workOrder.id);

    dispatch(setLoading(true));

    let response;
    try {
        response = await api.partProductUnplan.post(null, workOrderIds, {
            state_hash: getState().stateHash,
        });
    } catch (err) {
        dispatch(
            setError(
                err.statusCode,
                err.isValidationError ? err.firstError(true) : null,
            ),
        );
        dispatch(setLoading(false));
        return;
    }

    const oldIds = selectedActionGroups.map((group) =>
        actionGroupId(group.partProductId, group.workerId, group.workOrder.id),
    );
    // TODO: this is not the correct format for SELECT_LEAVES?
    const nowUnplannedIds = oldIds.map(convertPlannedActionGroupIdToUnplanned);

    dispatch(unplanLeaves(oldIds));

    dispatch(setStateHash(response.state_hash));
    dispatch(receiveUnplannedProducts(oldIds, response.data));
    dispatch(selectLeaves(nowUnplannedIds));
    dispatch(setLoading(false));
};

export const requestRevokeActionGroups = () => async (dispatch, getState) => {
    const state = getState();
    const selectedActionGroups = getSelectedActionGroups(state);

    const changeSet = [];
    const treeIds = [];
    const oldIds = [];

    selectedActionGroups.forEach((actionGroup) => {
        changeSet.push({
            worker_id: actionGroup.workerId,
            id: actionGroup.partProductId,
        });

        actionGroup.actions.forEach((action) => {
            treeIds.push(
                unassignedActionId(
                    actionGroup.partProductId,
                    action.id,
                    action.index,
                ),
            );
        });

        const sameWorkerWorkOrders = workOrdersSelectors.listForPartProductAndWorker(
            state,
            actionGroup.partProductId,
            actionGroup.workerId,
        );

        sameWorkerWorkOrders.forEach((workOrder) => {
            oldIds.push(
                actionGroupId(
                    workOrder.part_product,
                    workOrder.worker,
                    workOrder.id,
                ),
            );
        });
    });

    dispatch(setLoading(true));

    let response;
    try {
        response = await api.partProductRevoke.post(null, changeSet, {
            state_hash: state.stateHash,
        });
    } catch (err) {
        dispatch(
            setError(
                err.statusCode,
                err.isValidationError ? err.firstError(true) : null,
            ),
        );
        dispatch(setLoading(false));
        return;
    }
    dispatch(setStateHash(response.state_hash));
    dispatch(unplanLeaves(oldIds));
    dispatch(receiveUnplannedProducts(oldIds, response.data));
    dispatch(selectLeaves(treeIds));
    dispatch(setLoading(false));
};

// endregion
