import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';

import styles from './ErrorFloat.scss';

const ErrorFloat = ({ children, closable = true }) => {
    const [isShown, setShown] = useState(true);
    const [isFixed, setFixed] = useState(false);
    const [navHeight, setNavHeight] = useState(60);

    const handleScroll = () => {
        if (!isFixed && window.pageYOffset >= navHeight) {
            setFixed(true);
        } else if (isFixed && window.pageYOffset < navHeight) {
            setFixed(false);
        }
    };

    const handleResize = () => {
        const mainNavbar = document.getElementById('main-navbar');
        if (mainNavbar) setNavHeight(mainNavbar.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [isFixed, navHeight]);

    const handleOnClick = () => {
        if (closable) setShown(false);
    };

    const errorBarClasses = clsx(
        { fixed: isFixed, closable },
        styles.errorFloat,
    );
    return (
        isShown && (
            <div
                className={errorBarClasses}
                style={{ top: isFixed ? 0 : navHeight }}
                onClick={handleOnClick}
            >
                {children}
            </div>
        )
    );
};

ErrorFloat.propTypes = {
    closable: PropTypes.bool,
    children: PropTypes.node,
};

export default ErrorFloat;
