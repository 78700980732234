import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { stopCoolingProcessRoutine } from 'pipes/cooling/ducks/cooling';
import {
    STATE_KEY as SUBSCRIPTIONS_KEY,
    subscriptionsRoutine,
} from 'stations/ducks/subscriptions';
import { subscribersRoutine } from 'stations/ducks/subscribers';
import api from 'core/utils/api';

function* stopSubscriptionsAsync(param) {
    const subscription = yield select(
        (state) => state.entities[SUBSCRIPTIONS_KEY].byId[param.payload],
    );
    try {
        yield call(() =>
            api.stations.subscriptionsStop.post({ pk: param.payload }),
        );
        yield all([
            put(stopCoolingProcessRoutine.success(param.payload)),
            put(subscriptionsRoutine.delete(param.payload)),
            put(subscribersRoutine.delete(subscription.subscriber)),
        ]);
    } catch (error) {
        yield put(stopCoolingProcessRoutine.failure(error));
    }
}

export function* watchStopSubscriptions() {
    yield takeEvery(stopCoolingProcessRoutine.TRIGGER, stopSubscriptionsAsync);
}
