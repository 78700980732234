import createEntityDuck from 'core/ducks/generic/entity';
import { getIsLoading } from 'core/ducks/common';

import { STATE_KEY as SUBSCRIPTIONS_KEY } from './subscriptions';

export const STATE_KEY = 'stations';

// NOTE: Needs to be kept in sync with Django models
export const STATION_TYPES = {
    COOLING: 1,
    OPERATION: 3,
};

const getStationsBySubscription = (state, subscriptionId) =>
    (
        state.entities[SUBSCRIPTIONS_KEY].byId[subscriptionId]?.stations || []
    ).map((stationId) => state.entities[STATE_KEY].byId[stationId]);

const getListAsc = (state) => Object.values(state.entities[STATE_KEY].byId);

const getStationsLoading = (state) => getIsLoading(state, [STATE_KEY]);

const [stationsReducer, stationsRoutine, selectors] = createEntityDuck(
    STATE_KEY,
);

const stationsSelectors = {
    ...selectors,
    getStationsBySubscription,
    getStationsLoading,
    getListAsc,
};

export default stationsReducer;
export { stationsRoutine, stationsSelectors };
