import { BODY_TYPE, COMPONENT_TYPE_LABELS } from 'parts/workplan/constants';

/**
 * Format a component's label.
 *
 * @param {WorkPlanComponent} component
 * @param {string} postfix
 *
 * @return {string}
 */
export const formatComponentLabel = (component, postfix = '') =>
    `${COMPONENT_TYPE_LABELS[component.verbose].toUpperCase()}${postfix}`;

/**
 * Get the postfix that should be shown in a component's label. The function
 * accepts the whole work plan and the index of the component since those are
 * required to check check if there is BODY component or not.
 *
 * In case if there is no BODY component it increases index by 1, the same if
 * index is less than BODY component has. Otherwise just use the index.
 *
 * For example, this is how the function would return in the following cases:
 * * Components: END, BODY, END - Index: 0 - Returns '1'
 * * Components: END, BODY, END - Index: 1 - Returns ''
 * * Components: END, BODY, END - Index: 2 - Returns '2'
 *
 * @param {WorkPlan} workPlan - the work plan including all components for the
 *      part product
 * @param {number} index - index of the current component
 *
 * @returns {string}
 */
export const getComponentPostfix = (workPlan, index) => {
    // This component is BODY so it doesn't need a postfix
    if (workPlan[index].type === BODY_TYPE) return '';

    const bodyIndex = workPlan.findIndex((part) => part.type === BODY_TYPE);
    const postfix = index < bodyIndex || bodyIndex === -1 ? index + 1 : index;

    return postfix.toString();
};
