import moment from 'moment';
import { createRoutine } from 'redux-saga-routines';

import createEntityDuck from 'core/ducks/generic/entity';
import { operationsPageRoutine } from 'parts/operations/sagas/operationsViewSaga';

/**
 * @typedef {object} TimeTrackerNormalized
 * @property {number} id
 * @property {number} work_order
 * @property {string} start_time
 * @property {string} [end_time]
 */

export const STATE_KEY = 'timeTrackers';

export const startTimeTrackerRoutine = createRoutine('startTimeTrackerRoutine');
export const pauseTimeTrackerRoutine = createRoutine('pauseTimeTrackerRoutine');
export const stopTimeTrackerRoutine = createRoutine('stopTimeTrackerRoutine');

const [baseReducer, timeTrackersRoutine, selectors] = createEntityDuck(
    STATE_KEY,
);

const timeTrackersReducer = (currentState = undefined, action) => {
    const state = baseReducer(currentState, action);

    switch (action.type) {
        case operationsPageRoutine.SUCCESS:
            return baseReducer(
                state,
                timeTrackersRoutine.success({
                    byId: action.payload.timeTrackers ?? {},
                }),
            );
        case startTimeTrackerRoutine.SUCCESS:
        case pauseTimeTrackerRoutine.SUCCESS:
        case stopTimeTrackerRoutine.SUCCESS:
            // Update a single time tracker in the Redux store
            return baseReducer(
                state,
                timeTrackersRoutine.success({
                    byId: {
                        [action.payload.timeTracker.id]:
                            action.payload.timeTracker,
                    },
                }),
            );

        default:
            return state;
    }
};

const timeTrackersSelectors = {
    ...selectors,
    getListByWorkOrderId: (state, workOrderId) =>
        selectors
            .getList(state)
            .filter((timer) => timer.work_order === workOrderId)
            .sort((a, b) => (a.id > b.id ? 1 : -1)),
    getNewestTracker: (state, workOrderId) => {
        const sorted = selectors
            .getList(state)
            .filter((timer) => timer.work_order === workOrderId)
            .sort((a, b) =>
                moment(a.start_time).isAfter(moment(b.start_time)) ? -1 : 1,
            );

        return sorted.length ? sorted[0] : null;
    },
    getOldestTracker: (state, workOrderId) => {
        const sorted = selectors
            .getList(state)
            .filter((timer) => timer.work_order === workOrderId)
            .sort((a, b) =>
                moment(a.start_time).isAfter(moment(b.start_time)) ? -1 : 1,
            );

        return sorted.length ? sorted[sorted.length - 1] : null;
    },
};

export default timeTrackersReducer;
export { timeTrackersRoutine, timeTrackersSelectors };
