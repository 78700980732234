import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'tg-modal';

import { gettext } from 'core/utils/text';
import ArgsType = jest.ArgsType;

export const ConfirmModal: React.FC<{
    title: string;
    isOpen: boolean;
    isValid?: boolean;
    isStatic?: boolean;
    onConfirm?: () => void;
    onCancel: () => void;
    children?: React.ReactNode;
    yesText?: string;
    noText?: string;
}> = ({
    title,
    isOpen,
    isValid = true,
    isStatic = false,
    onConfirm,
    onCancel: outerOnCancel,
    children,
    yesText = gettext('Yes'),
    noText = gettext('No'),
}) => {
    const onCancel = React.useCallback(
        (e: Event | ArgsType<React.MouseEventHandler<Button>>[0]) => {
            e.stopPropagation();
            outerOnCancel();
        },
        [outerOnCancel]
    );

    // If we don't pass in undefined, then enter key will trigger the onConfirm, even if invalid and close the modal
    const onConfirmHandle = useCallback(
        () => (isValid ? onConfirm : undefined),
        [isValid, onConfirm]
    );

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onCancel={onCancel}
                onConfirm={onConfirmHandle}
                isStatic={isStatic}
            >
                <Modal.Header addClose={false}>{title}</Modal.Header>

                {children ? <Modal.Body>{children}</Modal.Body> : null}
                <div className="tg-modal-footer">
                    <Button
                        bsStyle="danger"
                        className="btn-half"
                        onClick={onCancel}
                    >
                        {noText}
                    </Button>
                    <Button
                        bsStyle="success"
                        className="btn-half"
                        disabled={!isValid}
                        onClick={onConfirm}
                    >
                        {yesText}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
