// An idea of this pattern: https://bit.ly/2NDXjEg

export default (state = {}, action) => {
    const { type, payload } = action;
    const matches = /(.*)\/(TRIGGER|REQUEST|FAILURE)(_DETAILS)*/.exec(type);

    // ignore all actions except TRIGGER/REQUEST/FAILURE
    if (!matches) return state;

    const [, stateKey, requestState, details] = matches;
    const storageKey = details ? stateKey + 'Details' : stateKey;
    // store error message when FAILURE and clean when REQUEST
    return {
        ...state,
        [storageKey]: requestState === 'FAILURE' ? payload : null,
    };
};

/**
 * Selector function that returns the first error messages for stateKeys
 * we assume when any request fails on a page that requires multiple API calls, we shows the first error
 *
 * @param state
 * @param {string[]} stateKeys
 * @returns {Object}
 */
export const getErrors = (state, stateKeys) =>
    stateKeys
        .map((key) => state.errors[key])
        .filter(Boolean)
        .shift() || null;
