import { all } from 'redux-saga/effects';

import { watchFetchSubscriptions } from './fetch';
import { watchFetchSubscription } from './fetchOne';
import { watchStopSubscriptions } from './stop';
import { watchChangeStations } from './changeStations';

export function* watchSubscriptions() {
    yield all([
        watchFetchSubscriptions(),
        watchFetchSubscription(),
        watchStopSubscriptions(),
        watchChangeStations(),
    ]);
}
